<h4>Test Component</h4>
<!--
<div class="container">
  <div class="row">
    <div class="col-sm">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="listNoteButton()">List Notes</button>
    </div>
    <div class="col-sm">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="addNoteButton()">Add Note</button>
    </div>
    <div class="col-sm">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="editNoteButton()">Edit Note</button>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-sm">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="listPaymentButton()">List Payments</button>
    </div>
    <div class="col-sm">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="addPaymentButton()">Add Payment</button>
    </div>
    <div class="col-sm">
      <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="editPaymentButton()">Edit Payment</button>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-sm">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Toggle dropdown</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button class="dropdown-item">Action - 1</button>
          <button class="dropdown-item">Another Action</button>
          <button class="dropdown-item">Something else is here</button>
        </div>
      </div>
    </div>
  </div>
</div>
-->

