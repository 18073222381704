<div>
    <h2 class="text-muted">Dashboard <small>Statistics Overview</small></h2>
    <div class="row ">
        <!--
        <div class=" col-sm-4">
            <app-stat [bgClass]="'primary'" [icon]="'fa-tasks'" count={{activeCount}} [label]="'Total vehicles'"></app-stat>
        </div>
        -->

        <div *ngIf="contractCount == activeVehicleCount" class=" col-sm-3">
            <app-stat [bgClass]="'success'" [icon]="'fa-tasks'" [count]=contractCount [label]="'Active Contracts'">
            </app-stat>
        </div>

        <div *ngIf="contractCount != activeVehicleCount" class=" col-sm-3">
                <app-stat [bgClass]="'warning'" [icon]="'fa-tasks'" [count]=contractCount [label]="'Active Contracts'">
                </app-stat>
            </div>

        <div *ngFor="let status of vehicleStatuses" class="col-sm-3 mb-3 align-items-center">
            <app-stat [bgClass]="'primary'" [icon]="'fa-tasks'" [count]=status.count [label]=status.status></app-stat>
        </div>

    </div>
</div>