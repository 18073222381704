import { Component, Input, OnInit } from '@angular/core';
import { ParseService } from 'src/app/services/parse.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from '../../models/customer';
import { NgbdModalBasic } from '../../modals/basic/basic.component';

@Component({
    selector: 'form-customer',
    templateUrl: './form-customer.component.html'
})
export class FormCustomerComponent implements OnInit {

    public customerTypes: Array<string> = ["Customer", "Additional", "Archived"];
    @Input() public customer: any;

    constructor(
        private parseService: ParseService,
        private customerService: CustomerService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() { }

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }

        return optionOne === optionTwo;
    }

    public uploadFile(attr: string) {
        let fileInput: any = document.createElement("INPUT");
        fileInput.setAttribute("type", "file");
        fileInput.multiple = false;
        fileInput.click();

        fileInput.onchange = (event) => {
            let file = fileInput.files[0];
            let parseFile = this.parseService.createParseFile(file.name, file);
            this.customer[attr] = parseFile;

            if (this.customer.id != null) {
                this.customerService.saveCustomer(this.customer).then(
                    (result: any) => {
                        let modalRef = this.modalService.open(NgbdModalBasic);
                        modalRef.componentInstance.modalTitle = 'Customer Saved!';
                        modalRef.componentInstance.modalMessage = 'The customer was saved successfully';
                        this.customer = result;
                    }, (error) => {
                        let modalRef = this.modalService.open(NgbdModalBasic);
                        modalRef.componentInstance.modalTitle = 'Error saving customer!';
                        modalRef.componentInstance.modalMessage = 'Error: ' + error;
                    }
                )
            }
        }
    }

    downloadFile(attr: string) {
        window.open(this.customer[attr].url(), '_blank');
    }

    public clearFile(attr: string) {
        this.customer[attr] = null;

        this.customerService.saveCustomer(this.customer).then(
            (result: any) => {
                let modalRef = this.modalService.open(NgbdModalBasic);
                modalRef.componentInstance.modalTitle = 'Customer Saved!';
                modalRef.componentInstance.modalMessage = 'The customer was saved successfully';
                this.customer = result;
            }, (error) => {
                let modalRef = this.modalService.open(NgbdModalBasic);
                modalRef.componentInstance.modalTitle = 'Error saving customer!';
                modalRef.componentInstance.modalMessage = 'Error: ' + error;
            }
        )
    }
}