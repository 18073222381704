import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'list-prospects',
    template:
        `
        <table id="notes-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
        <thead>
            <tr>
                <th>Object ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Contact Date</th>
                <th>Company</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let prospect of prospects" (click)="rowClicked(prospect)">
                <td>{{ prospect.id }}</td>
                <td>{{ prospect.firstName }}</td>
                <td>{{ prospect.lastName }}</td>
                <td *ngIf="prospect.contactDate != null">{{ prospect.contactDate.day }}-{{ prospect.contactDate.month }}-{{ prospect.contactDate.year }}</td>
                <td *ngIf="prospect.contactDate == null"></td>
                <td>{{ prospect.company }}</td>
                <td>{{ prospect.status }}</td>
            </tr>
        </tbody>
    </table>
    `
})
export class ListProspectsComponent implements OnInit {
    @Input() public prospects;
    @Output() clicked = new EventEmitter<any>();

    ngOnInit() { }

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }
}