// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: "1.3.13",
  appName: "Purple Cars (Development)",
  parseAppId:
    "JTZRI9MY4G4W3QLWR5Q2EYFHJQVSTTT33F6S2BRF18ZOKG4HVV57X4BC1WE8S012UVRLZ6BSZ8TIBQH7WZYOUVOHG10D1JP7TP8J",
  parseMasterId:
    "DY3666Z40TQSJJ124HNUR3OVET75BY3AYM5JK3RUOXKN6MJPBISTLBBJ7R87GC2TFQCP70HWYYH6ZQ3AFZEX2X76XOADX8UPPVYZ",
  //parseServerUrl: "http://localhost:1337/parse"
  parseServerUrl: "https://pcbo.purplecars.es/parse",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
