import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'list-notes',
    template:
        `
        <table id="notes-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
        <thead>
            <tr>
                <th>Object ID</th>
                <th>Title</th>
                <th>Content</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let note of notes" (click)="rowClicked(note)">
                <td>{{ note.id }}</td>
                <td>{{ note.title }}</td>
                <td>{{ note.content }}</td>
                <td>{{ note.status }}</td>
            </tr>
        </tbody>
    </table>
    `
})
export class ListNotesComponent implements OnInit {

    @Input() public notes = [];
    @Output() clicked = new EventEmitter<any>();

    ngOnInit() { }

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }
}