import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Debt } from '../../models/debt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalCustomerSelect } from '../../modals/customer-select/customer-select.component';
import { DebtService } from 'src/app/services/debt.service'
import { Customer } from '../../models/customer';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-edit-debts',
  templateUrl: './edit-debts.component.html',
  styleUrls: ['./edit-debts.component.css']
})
export class EditDebtsComponent implements OnInit {
  public debt: Debt;

  pageForm = this.formBuilder.group({
    id: new FormControl(''),
    idDisplay: new FormControl({value: '', disabled: true}),
    customer: new FormControl(''),
    customerDisplay: new FormControl({value: '', disabled: true}),
    debt: new FormControl(''),
    status: new FormControl(''),
    note: new FormControl(''),
  })

  constructor(
    private customerService: CustomerService,
    private debtService: DebtService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    let objectID = this.route.snapshot.paramMap.get("objectID");
    this.debt = new Debt();
    if (objectID != null ) {
      this.debt = await this.debtService.get(objectID);
      this.populateFormGroup(this.debt);
    }
  }

  get(objectID: string) {
    this.debtService.get(objectID).then((result) => {
      this.debt = result;
      this.populateFormGroup(this.debt);
    })
  }

  populateFormGroup(debt: Debt) {
    this.pageForm.controls['id'].setValue(debt.id);
    this.pageForm.controls['idDisplay'].setValue(debt.id);
    this.pageForm.controls['customer'].setValue(debt.customer);
    this.pageForm.controls['customerDisplay'].setValue(`${debt.customer.get('firstName')} ${debt.customer.get('lastName')}`);
    this.pageForm.controls['debt'].setValue(debt.debt);
    this.pageForm.controls['status'].setValue(debt.status);
    this.pageForm.controls['note'].setValue(debt.note);
  }

  submitButton(formValue) {

    if (this.pageForm.valid) {
      if ( formValue.id ) {
        this.debt.id = formValue.id;
      }
      this.debt.customer = formValue.customer;
      this.debt.debt = parseFloat(formValue.debt);
      this.debt.status = formValue.status;
      this.debt.note = formValue.note;
      
      this.debtService.save(this.debt).then(async (result: any) => {
        await result.customer.fetch();
        this.debt = result;
        this.populateFormGroup(result);
      }, (error) => {
        alert(error);
      })
    } else {
      alert("Form is invalid!");
    }

  }

  selectCustomerButton() {
    this.modalService.open(NgbdModalCustomerSelect, { size: 'lg' }).result.then((result) => {
      this.selectCustomer(result);
    }, (error) => {
      alert(error);
    });
  }

  selectCustomer(customer: Customer) {
    this.pageForm.controls['customer'].setValue(customer);
    this.pageForm.controls['customerDisplay'].setValue(`${customer.firstName} ${customer.lastName}`);
  }

}
