import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Note } from '../../models/note';

@Component({
  selector: 'ngbd-modal-note-select',
  templateUrl: './note-select.component.html'
})
export class NgbdModalNoteSelect {
  public filterString: any;

  @Input() modalTitle;
  @Input() modalMessage;
  @Input() note;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  saveButton() {
    this.activeModal.close(this.note);
  }

}