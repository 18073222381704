import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'form-agreement',
    templateUrl: './form-agreement.component.html'
})
export class FormAgreementComponent implements OnInit {

    @Input() public agreement: any;

    constructor() { }

    ngOnInit() { }

}