import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from '../../models/vehicle';
import { ParseService } from 'src/app/services/parse.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { NgbdModalBasic } from '../../modals/basic/basic.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'form-vehicle',
    templateUrl: './form-vehicle.component.html'
})
export class FormVehicleComponent implements OnInit {

    //public statuses: Array<string> = ["Rented", "Rent to buy", "Available", "Courtesy", "Sale", "Other", "Archived"];
    public statuses: Array<string> = ["Courtesy", "Rented", "Reservation", "Rent to buy", "Write Off", "For Sale", "Sold", "Mechanical Repair", "Paint Work", "Available", "ITV", "Pending Delivery", "Archived"];
    public registraionStatuses: Array<string> = ["Rental", "Non-Rental"];
    public locations: Array<string> = ["Unit", "Valet", "Office", "Rented"];
    public brands: Array<string> = ["Purple Cars", "Runabout Rentals"]
    @Input() public vehicle: Vehicle;

    constructor(
        private parseService: ParseService,
        private vehicleService: VehicleService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() { }

    uploadImageButton() {
        let fileInput: any = document.createElement("INPUT");
        fileInput.setAttribute("type", "file");
        fileInput.multiple = false;
        fileInput.click();

        fileInput.onchange = (event) => {
            let file = fileInput.files[0];
            let parseFile = this.parseService.createParseFile(file.name, file);
            this.vehicle.image = parseFile;
        }

    }

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }

        return optionOne === optionTwo;
    }

    public uploadFile(attr: string) {
        let fileInput: any = document.createElement("INPUT");
        fileInput.setAttribute("type", "file");
        fileInput.multiple = false;
        fileInput.click();

        fileInput.onchange = (event) => {
            let file = fileInput.files[0];
            let parseFile = this.parseService.createParseFile(file.name, file);
            this.vehicle[attr] = parseFile;

            if (this.vehicle.id != null) {
                this.vehicleService.saveVehicle(this.vehicle).then(
                    (result: any) => {
                        let modalRef = this.modalService.open(NgbdModalBasic);
                        modalRef.componentInstance.modalTitle = 'Vehicle Saved!';
                        modalRef.componentInstance.modalMessage = 'The vehicle was saved successfully';
                        this.vehicle = result;
                    }, (error) => {
                        let modalRef = this.modalService.open(NgbdModalBasic);
                        modalRef.componentInstance.modalTitle = 'Error saving vehicle!';
                        modalRef.componentInstance.modalMessage = 'Error: ' + error;
                    }
                )
            }
        }
    }

    downloadFile(attr: string) {
        window.open(this.vehicle[attr].url(), '_blank');
    }

    public clearFile(attr: string) {
        this.vehicle[attr] = null;

        this.vehicleService.saveVehicle(this.vehicle).then(
            (result: any) => {
                let modalRef = this.modalService.open(NgbdModalBasic);
                modalRef.componentInstance.modalTitle = 'Vehicle Saved!';
                modalRef.componentInstance.modalMessage = 'The vehicle was saved successfully';
                this.vehicle = result;
            }, (error) => {
                let modalRef = this.modalService.open(NgbdModalBasic);
                modalRef.componentInstance.modalTitle = 'Error saving vehicle!';
                modalRef.componentInstance.modalMessage = 'Error: ' + error;
            }
        )
    }


}
