<div class="form-row mb-3">
  <div class="col m-1">

    <div class="form-row mb-3">
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Object ID:</div>
          </div>
          <input [(ngModel)]="vehicle.id" type="text" class="form-control" placeholder="Vehicle ID" id="id" name="id"
            disabled>
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Vehicle ID:</div>
          </div>
          <input [(ngModel)]="vehicle.vehicleID" type="text" class="form-control" placeholder="Vehicle ID"
            id="vehicleID" name="vehicleID">
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Status:</div>
          </div>
          <select [(ngModel)]="vehicle.status" [compareWith]="compareFn" class="form-control" id="status" name="status">
            <option value="undefined" disabled selected>Please Select</option>
            <option *ngFor="let status of statuses" [ngValue]="status">{{ status }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Registration</div>
          </div>
          <input [(ngModel)]="vehicle.registration" type="text" class="form-control" placeholder="Registration"
            id="registration" name="registration">
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Make</div>
          </div>
          <input [(ngModel)]="vehicle.make" type="text" class="form-control" placeholder="Make" id="make" name="make">
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Model</div>
          </div>
          <input [(ngModel)]="vehicle.model" type="text" class="form-control" placeholder="Model" id="model"
            name="model">
        </div>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Colour</div>
          </div>
          <input [(ngModel)]="vehicle.colour" type="text" class="form-control" placeholder="Colour" id="colour"
            name="colour">
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Manufacture Year</div>
          </div>
          <input [(ngModel)]="vehicle.year" type="text" class="form-control" placeholder="Manufacture Year" id="year"
            name="year">
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Mileage</div>
          </div>
          <input [(ngModel)]="vehicle.mileage" type="text" class="form-control" placeholder="Mileage" id="mileage"
            name="mileage">
        </div>
      </div>
    </div>

    <div class="form-row mb-3">

      <div class="col">
        <div class="input-group datepicker-input">
          <div class="input-group-prepend">
            <div class="input-group-text">ITV Date</div>
          </div>
          <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="vehicle.itvDate" ngbDatepicker
            #itvDate="ngbDatepicker" id="itvDate" name="itvDate">
          <button class="input-group-addon" (click)="itvDate.toggle()" type="button">
            <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
            <span class="fa fa-calendar"></span>
          </button>
        </div>
      </div>

      <div class="col">
        <div class="input-group datepicker-input">
          <div class="input-group-prepend">
            <div class="input-group-text">ITV Booking Date</div>
          </div>
          <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="vehicle.itvBookingDate" ngbDatepicker
            #itvBookingDate="ngbDatepicker" id="itvBookingDate" name="itvBookingDate">
          <button class="input-group-addon" (click)="itvBookingDate.toggle()" type="button">
            <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
            <span class="fa fa-calendar"></span>
          </button>
        </div>
      </div>

      <div class="col">
        <div class="input-group datepicker-input">
          <div class="input-group-prepend">
            <div class="input-group-text">Service Date</div>
          </div>
          <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="vehicle.serviceDate" ngbDatepicker
            #serviceDate="ngbDatepicker" id="serviceDate" name="serviceDate">
          <button class="input-group-addon" (click)="serviceDate.toggle()" type="button">
            <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
            <span class="fa fa-calendar"></span>
          </button>
        </div>
      </div>
      <div class="col">
        <div class="input-group datepicker-input">
          <div class="input-group-prepend">
            <div class="input-group-text">Insurance Expiry Date</div>
          </div>
          <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="vehicle.insuranceExpieryDate" ngbDatepicker
            #insuranceExpieryDate="ngbDatepicker" id="insuranceExpieryDate" name="insuranceExpieryDate">
          <button class="input-group-addon" (click)="insuranceExpieryDate.toggle()" type="button">
            <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
            <span class="fa fa-calendar"></span>
          </button>
        </div>
      </div>
    </div>

    <hr>

    <div class="form-row mb-3">
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Purchase Price</div>
          </div>
          <input [(ngModel)]="vehicle.purchasePrice" type="text" class="form-control" placeholder="Purchase Price"
            id="purchasePrice" name="purchasePrice">
        </div>
      </div>

      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Rental Price</div>
          </div>
          <input [(ngModel)]="vehicle.price" type="text" class="form-control" placeholder="Rental Price" id="price"
            name="price">
        </div>
      </div>

      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Sale Price</div>
          </div>
          <input [(ngModel)]="vehicle.salePrice" type="text" class="form-control" placeholder="Sale Price" id="salePrice"
            name="price">
        </div>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Registration Status:</div>
          </div>
          <select [(ngModel)]="vehicle.registraionStatus" [compareWith]="compareFn" class="form-control"
            id="registraionStatus" name="registraionStatus">
            <option value="undefined" disabled selected>Please Select</option>
            <option *ngFor="let status of registraionStatuses" [ngValue]="status">{{ status }}</option>
          </select>
        </div>
      </div>
      <!--
            <div class="col">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Location:</div>
                    </div>
                    <select [(ngModel)]="vehicle.location" [compareWith]="compareFn" class="form-control" id="location"
                        name="location">
                        <option value="undefined" disabled selected>Please Select</option>
                        <option *ngFor="let location of locations" [ngValue]="location">{{ location }}</option>
                    </select>
                </div>
            </div>
            -->
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Brand:</div>
          </div>
          <select [(ngModel)]="vehicle.brand" [compareWith]="compareFn" class="form-control" id="brand" name="brand">
            <option value="undefined" disabled selected>Please Select</option>
            <option *ngFor="let brand of brands" [ngValue]="brand">{{ brand }}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="input-group datepicker-input">
          <div class="input-group-prepend">
            <div class="input-group-text">Purchase Date</div>
          </div>
          <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="vehicle.purchaseDate" ngbDatepicker
            #purchaseDate="ngbDatepicker" id="purchaseDate" name="purchaseDate">
          <button class="input-group-addon" (click)="purchaseDate.toggle()" type="button">
            <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
            <span class="fa fa-calendar"></span>
          </button>
        </div>
      </div>
    </div>

    <hr>

    <div class="form-group row">
      <div class="col-sm-2">Screen Hide: </div>
      <div class="col-sm-10">
        <div class="form-check">
          <input [(ngModel)]="vehicle.screenHide" class="form-check-input" type="checkbox" id="screenHide"
            name="screenHide">
        </div>
      </div>
    </div>

    <div *ngIf="vehicle.id != null">
      <hr>
      <div class="form-row mb-3">
        <div class="col">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">permissoFile</div>
            </div>
            <input *ngIf="vehicle.permissoFile != null" type="text" class="form-control" id="permissoFile"
              name="permissoFile" value="{{ vehicle.permissoFile.name() }}" disabled>
            <input *ngIf="vehicle.permissoFile == null" type="text" class="form-control" id="permissoFile"
              name="permissoFile" value="Not Available" disabled>
            <div class="input-group-append">
              <button *ngIf="vehicle.permissoFile != null" (click)="downloadFile('permissoFile')"
                class="btn btn-success" type="button">Download</button>
              <button (click)="uploadFile('permissoFile')" class="btn btn-primary" type="button">Browse</button>
              <button *ngIf="vehicle.permissoFile != null" (click)="clearFile('permissoFile')" class="btn btn-danger"
                type="button"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">insuranceFile</div>
            </div>
            <input *ngIf="vehicle.insuranceFile != null" type="text" class="form-control" id="insuranceFile"
              name="insuranceFile" value="{{ vehicle.insuranceFile.name() }}" disabled>
            <input *ngIf="vehicle.insuranceFile == null" type="text" class="form-control" id="insuranceFile"
              name="insuranceFile" value="Not Available" disabled>
            <div class="input-group-append">
              <button *ngIf="vehicle.insuranceFile != null" (click)="downloadFile('insuranceFile')"
                class="btn btn-success" type="button">Download</button>
              <button (click)="uploadFile('insuranceFile')" class="btn btn-primary" type="button">Browse</button>
              <button *ngIf="vehicle.insuranceFile != null" (click)="clearFile('insuranceFile')" class="btn btn-danger"
                type="button"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">fichaFrontFile</div>
            </div>
            <input *ngIf="vehicle.fichaFrontFile != null" type="text" class="form-control" id="fichaFrontFile"
              name="fichaFrontFile" value="{{ vehicle.fichaFrontFile.name() }}" disabled>
            <input *ngIf="vehicle.fichaFrontFile == null" type="text" class="form-control" id="fichaFrontFile"
              name="fichaFrontFile" value="Not Available" disabled>
            <div class="input-group-append">
              <button *ngIf="vehicle.fichaFrontFile != null" (click)="downloadFile('fichaFrontFile')"
                class="btn btn-success" type="button">Download</button>
              <button (click)="uploadFile('fichaFrontFile')" class="btn btn-primary" type="button">Browse</button>
              <button *ngIf="vehicle.fichaFrontFile != null" (click)="clearFile('fichaFrontFile')"
                class="btn btn-danger" type="button"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row mb-3">

        <div class="col">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">fichaBackFile</div>
            </div>
            <input *ngIf="vehicle.fichaBackFile != null" type="text" class="form-control" id="fichaBackFile"
              name="fichaBackFile" value="{{ vehicle.fichaBackFile.name() }}" disabled>
            <input *ngIf="vehicle.fichaBackFile == null" type="text" class="form-control" id="fichaBackFile"
              name="fichaBackFile" value="Not Available" disabled>
            <div class="input-group-append">
              <button *ngIf="vehicle.fichaBackFile != null" (click)="downloadFile('fichaBackFile')"
                class="btn btn-success" type="button">Download</button>
              <button (click)="uploadFile('fichaBackFile')" class="btn btn-primary" type="button">Browse</button>
              <button *ngIf="vehicle.fichaBackFile != null" (click)="clearFile('fichaBackFile')" class="btn btn-danger"
                type="button"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">greenCardFile</div>
            </div>
            <input *ngIf="vehicle.greenCardFile != null" type="text" class="form-control" id="greenCardFile"
              name="greenCardFile" value="{{ vehicle.greenCardFile.name() }}" disabled>
            <input *ngIf="vehicle.greenCardFile == null" type="text" class="form-control" id="greenCardFile"
              name="greenCardFile" value="Not Available" disabled>
            <div class="input-group-append">
              <button *ngIf="vehicle.greenCardFile != null" (click)="downloadFile('greenCardFile')"
                class="btn btn-success" type="button">Download</button>
              <button (click)="uploadFile('greenCardFile')" class="btn btn-primary" type="button">Browse</button>
              <button *ngIf="vehicle.greenCardFile != null" (click)="clearFile('greenCardFile')" class="btn btn-danger"
                type="button"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
  <div class="col-2 p-5">
    <div class="row">
      <!--
            <img [src]="vehicle.image._url" class="align-self-center" style="width:100%; height:auto;">
            <button type="file" class="btn btn-primary btn-sm w-100" (click)="uploadFile('image')">Upload Image</button>
            -->
    </div>
  </div>

</div>
