import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { ParseService } from './parse.service';
import { Debt } from '../backoffice/models/debt';
import { CustomerService } from './customer.service';

/* This services uses the updated service structure */

@Injectable()
export class DebtService {

  constructor(
    private parseService: ParseService,
    private customerService: CustomerService,
  ) { }

  async destroy(debt: Debt){
    return new Promise((resolve, reject) => {
      debt.destroy().then((result) => {
        resolve(result);
      }, (error) => {
        reject(error);
      })
    })
  }

  async find(): Promise<any> {
    return new Promise((resolve, reject) => {
      let query = this.parseService.createParseQuery(new Debt());

      query.include('customer');
      query.find().then((results) => {
        resolve(results);
      }, (error) => {
        reject(error);
      });
    })
  }

  async findByCustomerName(value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let Customer = this.parseService.createParseSubclass("Customer");

      let firstNameQuery = this.parseService.createParseQuery(Customer);
      firstNameQuery.matches("firstName", value, 'i');

      let lastNameQuery = this.parseService.createParseQuery(Customer);
      lastNameQuery.matches("lastName", value, 'i');

      let customerQuery = new (Parse as any).Query.or(firstNameQuery, lastNameQuery);

      let query = this.parseService.createParseQuery(new Debt());
      query.matchesQuery("customer", customerQuery);
      

      query.include('customer');
      query.find().then((results) => {
        resolve(results);
      }, (error) => {
        reject(error);
      });
    })
  }

  async findByStatus(status: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let query = this.parseService.createParseQuery(new Debt());

      query.include('customer');
      query.equalTo('status', status);
      query.find().then((results) => {
        resolve(results);
      }, (error) => {
        reject(error);
      });
    })
  }

  async get(objectID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let query = this.parseService.createParseQuery(new Debt());

      query.include('customer');
      query.get(objectID).then((result) => {
        resolve(result);
      }, (error) => {
        reject(error);
      })
    })
  }

  async save(debt: Debt): Promise<any> {
    return new Promise(async (resolve, reject) => {
      debt.setACL(this.parseService.getStandardACL());
      debt.createdBy = this.parseService.getCurrentUser();

      debt.customer = await this.customerService.toParseObject(debt.customer);
      debt.save().then((result) => {
        resolve(result);
      }, (error) => {
        reject(error);
      })
    })
  }
}
