import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-basic',
  templateUrl: './basic.component.html'
})
export class NgbdModalBasic {
  @Input() modalTitle;
  @Input() modalMessage;

  constructor(public activeModal: NgbActiveModal) {}
}