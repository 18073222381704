<nav class="sidebar" [ngClass]="{sidebarPushRight: isActive, collapsed: collapsed}" (panright)="toggleCollapsed()">
    <div class="list-group">
        <a routerLink="/backoffice" [routerLinkActive]="['router-link-active']" class="list-group-item">
            <i class="fa fa-fw fa-dashboard"></i>&nbsp;
            <span>{{ 'Dashboard' }}</span>
        </a>
        <a routerLink="/backoffice/vehicle" [routerLinkActive]="['router-link-active']" class="list-group-item">
            <i class="fa fa-car"></i>&nbsp;
            <span>{{ 'Vehicles' }}</span>
        </a>
        <a routerLink="/screens/itv" [routerLinkActive]="['router-link-active']" class="list-group-item">
            <i class="fa fa-car"></i>&nbsp;
            <span>{{ 'ITV Screen' }}</span>
        </a>
    </div>

    <div class="toggle-button" [ngClass]="{collapsed: collapsed}" (click)="toggleCollapsed()">
        <i class="fa fa-fw fa-angle-double-left"></i>&nbsp;
        <span>{{ 'Collapse Sidebar' }}</span>
    </div>
</nav>