import { Injectable } from '@angular/core';
import { ParseService } from './parse.service';
import { Agreement } from '../backoffice/models/agreement';

@Injectable()
export class AgreementService {

    constructor(
        private parseService: ParseService
    ) { }

    async fromParseObject(obj) {
        let agreement: Agreement = new Agreement();
        agreement.id = obj.id;
        agreement.rentalAmount = obj.get("rentalAmount");
        agreement.depositAmount = obj.get("depositAmount");
        agreement.extrasAmount = obj.get("extrasAmount");
        agreement.paymentDay = obj.get("paymentDay");

        if (obj.get("startDate") != null) {
            agreement.startDate = this.parseService.toNgbDate(obj.get("startDate"));
        }

        if (obj.get("endDate") != null) {
            agreement.endDate = this.parseService.toNgbDate(obj.get("endDate"));
        }

        return agreement
    }

    async toParseObject(agreement: Agreement) {
        let obj = this.parseService.createParseInstance("Agreement");
        obj.id = agreement.id;
        obj.set("rentalAmount", Number(agreement.rentalAmount));
        obj.set("depositAmount", Number(agreement.depositAmount));
        obj.set("extrasAmount", Number(agreement.extrasAmount));
        obj.set("paymentDay", Number(agreement.paymentDay));

        if (agreement.startDate != null) {
            obj.set("startDate", this.parseService.fromNgbDate(agreement.startDate));
        } else {
            obj.set("startDate", agreement.startDate);
        }

        if (agreement.endDate != null) {
            obj.set("endDate", this.parseService.fromNgbDate(agreement.endDate));
        } else {
            obj.set("endDate", agreement.endDate);
        }

        return obj;
    }

}