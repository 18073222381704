import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/services/customer.service';
import { NgbdModalBasic } from '../basic/basic.component';

@Component({
  selector: 'ngbd-modal-customer-select',
  templateUrl: './customer-select.component.html'
})
export class NgbdModalCustomerSelect {
  public customers = [];
  public filterString: any;

  @Input() modalTitle;
  @Input() modalMessage;

  constructor(
    private customerService: CustomerService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
    this.getAllCustomers();
  }

  getAllCustomers(): Promise<any> {
    return this.customerService.getAllCustomers().then(
      (result: any) => {
        this.customers = result;
      }, (error) => {
        let modalRef = this.modalService.open(NgbdModalBasic);
        modalRef.componentInstance.modalTitle = 'Error loading customers!';
        modalRef.componentInstance.modalMessage = 'Error: ' + error;
      }
    )
  }

  searchCustomersByLastName(lastName: string) {
    return this.customerService.searchCustomersByLastName(lastName).then(
      (result: any) => {
        this.customers = result;
      }, (error) => {
        let modalRef = this.modalService.open(NgbdModalBasic);
        modalRef.componentInstance.modalTitle = 'Error searching customers!';
        modalRef.componentInstance.modalMessage = 'Error: ' + error;
      }
    )
  }

  rowClicked(event) {
    this.activeModal.close(event);
  }

}