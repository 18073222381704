import { Component, OnInit } from '@angular/core';
import { DebtService } from 'src/app/services/debt.service';
import { Debt } from '../../models/debt';

@Component({
  selector: 'app-list-debts',
  templateUrl: './list-debts.component.html',
  styleUrls: ['./list-debts.component.css']
})
export class ListDebtsComponent implements OnInit {

  public debts: Debt[] = [];
  public filterString: string;

  constructor(
    private debtService: DebtService
  ) { }

  ngOnInit() {
    this.findByStatus('open');
  }

  find() {
    this.debtService.find().then((results) => {
      this.debts = results;
    }, (error) => {
      alert(error);
    })
  }

  findByCustomerName(value: string) {
    this.debtService.findByCustomerName(value).then((results) => {
      this.debts = results;
    }, (error) => {
      alert(error);
    })
  }

  findByStatus(status: string) {
    this.debtService.findByStatus(status).then((results) => {
      this.debts = results;
    }, (error) => {
      alert(error);
    })
  }

}
