import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { Customer } from './customer';

export class Prospect {
    id: string = null;
    firstName: string = null;
    lastName: string = null;
    company: string = undefined;
    serviceNotes: string = null;
    contactDate: NgbDate = null;
    status: string = undefined;
    telephone: string = null;
    email: string = null;
    preferedContact: string = undefined;
    followUp: string = null;
    leadSource: string = undefined;
    paidCustomerDate: NgbDate = null;
    customerNumber: number = null;
    assignee: string = undefined;
    customer: Customer = undefined;
}