import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-note-form',
    template:
    `
    <div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <form-note [note]="note"></form-note>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="saveButton()">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Close</button>
    </div>
    `
})
export class NgbdModalNoteForm {
    public filterString: any;

    @Input() modalTitle;
    @Input() modalMessage;
    @Input() note;

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    saveButton() {
        this.activeModal.close(this.note);
    }

}