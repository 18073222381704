<div class="form-row mb-3">

    <div class="col">
        <div class="form-row mb-3">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Object ID:</div>
                </div>
                <input [(ngModel)]="task.id" type="text" class="form-control" placeholder="New Task" id="id" name="id"
                    disabled>
            </div>
        </div>


        <div class="form-row mb-3">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Link:</div>
                </div>
                <input [(ngModel)]="task.link" type="text" class="form-control" placeholder="Link" id="link" name="link"
                    disabled>
            </div>
        </div>

        <div class="form-row mb-3">
            <div class="input-group datepicker-input">
                <div class="input-group-prepend">
                    <div class="input-group-text">Action Date</div>
                </div>
                <input [(ngModel)]="task.actionDate" class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker
                    #actionDate="ngbDatepicker" id="actionDate" name="actionDate">
                <button class="input-group-addon" (click)="actionDate.toggle()" type="button">
                    <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                    <span class="fa fa-calendar"></span>
                </button>
            </div>
        </div>

        <div class="form-row mb-3">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Assigned To:</div>
                </div>
                <select [(ngModel)]="task.assignedTo" [compareWith]="compareFn" class="form-control" id="assignedTo"
                    name="assignedTo">
                    <option value=undefined selected>Unassigned</option>
                    <option *ngFor="let user of users" [ngValue]="user" >{{ user.get("username") }}</option>
                </select>
            </div>
        </div>

        <div class="form-row mb-3">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Company:</div>
                </div>
                <select [(ngModel)]="task.company" [compareWith]="compareFn" class="form-control" id="company"
                    name="company">
                    <option value="undefined" disabled selected>Please Select</option>
                    <option *ngFor="let company of companies" [ngValue]="company">{{ company }}</option>
                </select>
            </div>
        </div>

        <div class="form-row mb-3">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Status:</div>
                </div>
                <select [(ngModel)]="task.status" [compareWith]="compareFn" class="form-control" id="status"
                    name="status">
                    <option value="undefined" disabled selected>Please Select</option>
                    <option *ngFor="let status of statuses" [ngValue]="status">{{ status }}</option>
                </select>
            </div>
        </div>

    </div>



    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Description:</div>
            </div>

            <textarea [(ngModel)]="task.description" class="form-control" rows="8" id="description"></textarea>

        </div>
    </div>

</div>