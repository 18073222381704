import { Component, OnInit } from '@angular/core';
import { VehicleService } from 'src/app/services/vehicle.service';
import { NgbdModalBasic } from '../modals/basic/basic.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractService } from 'src/app/services/contract.service';

@Component({
    selector: 'app-login',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {

    public activeCount    = 0;
    public rentedCount    = 0;
    public rentToBuyCount = 0;
    public contractCount  = 0;
    public vehicleStatuses = [];
    public activeVehicleCount = 0;

    constructor(
        private contractService: ContractService,
        private vehicleService: VehicleService,
        private modalService: NgbModal
    ) {}

    async ngOnInit() {
        this.getVehicleStatuses();
        this.activeCount = await this.vehicleService.getActiveVehicleCount();
        this.rentedCount = await this.vehicleService.getCountByStatus("Rented");
        this.rentToBuyCount = await this.vehicleService.getCountByStatus("Rent to buy");
        this.contractCount = await this.contractService.getCountByStatus("Active");

        this.activeVehicleCount = this.rentedCount + this.rentToBuyCount;

        this.vehicleService.testCloudJob();
    }

    getVehicleStatuses(): Promise<any> {
        return this.vehicleService.getVehicleStatuses().then(
            async (result: any) => {
                for ( var i of result) {
                    let c = await this.vehicleService.countVehicleStatus(i);
                    this.vehicleStatuses.push({status: i, count: c});
                }
            }, (error) => {
            }
        )
    }

    async countActiveContracts(status: string) {
        return this.vehicleService.getCountByStatus(status).then(
            (result: any) => {
            }, (error) => {
                let modalRef = this.modalService.open(NgbdModalBasic);
                modalRef.componentInstance.modalTitle = 'Error loading prospects!';
                modalRef.componentInstance.modalMessage = 'Error: ' + error;
            }
        )
    }

    async countVehicleStatus(status: string) {
        return this.vehicleService.countVehicleStatus(status).then(
            (result: any) => {
            }, (error) => {
                let modalRef = this.modalService.open(NgbdModalBasic);
                modalRef.componentInstance.modalTitle = 'Error loading prospects!';
                modalRef.componentInstance.modalMessage = 'Error: ' + error;
            }
        )
    }


}