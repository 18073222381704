import { Component, OnInit } from '@angular/core';
import { NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBasic } from '../../modals/basic/basic.component';

import { NoteService } from '../../../services/note.service';
import { NgbdModalNoteSelect } from '../../modals/note-select/note-select.component';
import { NgbdModalNoteForm } from '../../modals/note-form/note-form.component';

import { PaymentService } from 'src/app/services/payment.service';
import { NgbdModalPaymentSelect } from '../../modals/payment-select/payment-select.component';
import { NgbdModalPaymentForm } from '../../modals/payment-form/payment-form.component';

@Component({
    selector: 'test-default',
    templateUrl: './test.component.html',
})
export class TestComponent implements OnInit {

    constructor(
        private modalService: NgbModal,
        private noteService: NoteService,
        private paymentService: PaymentService,
    ) { }

    ngOnInit() { }

}