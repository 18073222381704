import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Note } from './note';

export class Customer {
    id: string = null;
    customerID: string = null;
    firstName: string = null;
    lastName: string = null;
    birthDate: NgbDate = null;
    address: string = null;
    city: string = null;
    country: string = null;
    postCode: string = null;
    contactNumber: string = null;
    contactEMail: string = null;
    nie: string = null;
    licenseNumber: string = null;
    licenseExpiryDate: NgbDate = null;
    customerType: Array<string> = null; // Customer, Additional, Archived.
    notes: Array<Note> = null;
    
    licenceFile: any = null;
    passportFile: any = null;
    dvlaFile: any = null;
    addressFile: any = null;
    agreementFile: any = null;
}