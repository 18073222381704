<h4>Customer Information</h4>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Object ID:</div>
            </div>
            <input [(ngModel)]="customer.id" type="text" class="form-control" placeholder="Customer ID" id="id"
                name="id" disabled>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Customer ID:</div>
            </div>
            <input [(ngModel)]="customer.customerID" type="text" class="form-control" placeholder="Customer ID"
                id="customerID" name="customerID">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Customer Type:</div>
            </div>
            <select [(ngModel)]="customer.customerType" [compareWith]="compareFn" class="form-control" id="customerType"
                name="customerType">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let ctype of customerTypes" [ngValue]="ctype">{{ ctype }}</option>
            </select>
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">First Name:</div>
            </div>
            <input [(ngModel)]="customer.firstName" type="text" class="form-control" placeholder="First Name"
                id="firstName" name="firstName">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Last Name:</div>
            </div>
            <input [(ngModel)]="customer.lastName" type="text" class="form-control" placeholder="Last Name"
                id="lastName" name="lastName">
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">Date of Birth</div>
            </div>
            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="customer.birthDate" ngbDatepicker
                #birthDate="ngbDatepicker" id="birthDate" name="birthDate">
            <button class="input-group-addon" (click)="birthDate.toggle()" type="button">
                <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                <span class="fa fa-calendar"></span>
            </button>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">NIE:</div>
            </div>
            <input [(ngModel)]="customer.nie" type="text" class="form-control" placeholder="NIE" id="nie" name="nie">
        </div>
    </div>
</div>
<hr>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Address:</div>
            </div>
            <input [(ngModel)]="customer.address" type="text" class="form-control" placeholder="Address" id="address"
                name="address">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">City:</div>
            </div>
            <input [(ngModel)]="customer.city" type="text" class="form-control" placeholder="City" id="city"
                name="city">
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Country:</div>
            </div>
            <input [(ngModel)]="customer.country" type="text" class="form-control" placeholder="Country" id="country"
                name="country">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Post Code:</div>
            </div>
            <input [(ngModel)]="customer.postCode" type="text" class="form-control" placeholder="Post Code"
                id="postCode" name="postCode">
        </div>
    </div>
</div>
<hr>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Telephone:</div>
            </div>
            <input [(ngModel)]="customer.contactNumber" type="text" class="form-control" placeholder="Telephone"
                id="contactNumber" name="contactNumber">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Email:</div>
            </div>
            <input [(ngModel)]="customer.contactEMail" type="text" class="form-control" placeholder="Email"
                id="contactEMail" name="contactEMail">
        </div>
    </div>
</div>
<hr>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Licence Number:</div>
            </div>
            <input [(ngModel)]="customer.licenseNumber" type="text" class="form-control" placeholder="Licence Number"
                id="licenseNumber" name="licenseNumber">
        </div>
    </div>
    <div class="col">
        <div class="input-group">


            <div class="input-group datepicker-input">
                <div class="input-group-prepend">
                    <div class="input-group-text">Licence Expiry</div>
                </div>
                <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="customer.licenseExpiryDate"
                    ngbDatepicker #licenseExpiryDate="ngbDatepicker" id="licenseExpiryDate" name="licenseExpiryDate">
                <button class="input-group-addon" (click)="licenseExpiryDate.toggle()" type="button">
                    <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                    <span class="fa fa-calendar"></span>
                </button>
            </div>


        </div>
    </div>
</div>

<hr>

<div *ngIf="customer.id != null">
    <div class="form-row mb-3">
        <div class="col">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Driving Licence</div>
                </div>
                <input *ngIf="customer.licenceFile != null" type="text" class="form-control" id="licenceFile"
                    name="licenceFile" value="{{ customer.licenceFile.name() }}" disabled>
                <input *ngIf="customer.licenceFile == null" type="text" class="form-control" id="licenceFile"
                    name="licenceFile" value="Not Available" disabled>
                <div class="input-group-append">
                    <button *ngIf="customer.licenceFile != null" (click)="downloadFile('licenceFile')"
                        class="btn btn-success" type="button">Download</button>
                    <button (click)="uploadFile('licenceFile')" class="btn btn-primary" type="button">Browse</button>
                    <button *ngIf="customer.licenceFile != null" (click)="clearFile('licenceFile')"
                        class="btn btn-danger" type="button"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Passport</div>
                </div>
                <input *ngIf="customer.passportFile != null" type="text" class="form-control" id="passportFile"
                    name="passportFile" value="{{ customer.passportFile.name() }}" disabled>
                <input *ngIf="customer.passportFile == null" type="text" class="form-control" id="passportFile"
                    name="passportFile" value="Not Available" disabled>
                <div class="input-group-append">
                    <button *ngIf="customer.passportFile != null" (click)="downloadFile('passportFile')"
                        class="btn btn-success" type="button">Download</button>
                    <button (click)="uploadFile('passportFile')" class="btn btn-primary" type="button">Browse</button>
                    <button *ngIf="customer.passportFile != null" (click)="clearFile('passportFile')"
                        class="btn btn-danger" type="button"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </div>
    </div>

    <div class="form-row mb-3">
        <div class="col">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">DVLA Check</div>
                </div>
                <input *ngIf="customer.dvlaFile != null" type="text" class="form-control" id="dvlaFile" name="dvlaFile"
                    value="{{ customer.dvlaFile.name() }}" disabled>
                <input *ngIf="customer.dvlaFile == null" type="text" class="form-control" id="dvlaFile" name="dvlaFile"
                    value="Not Available" disabled>
                <div class="input-group-append">
                    <button *ngIf="customer.dvlaFile != null" (click)="downloadFile('dvlaFile')" class="btn btn-success"
                        type="button">Download</button>
                    <button (click)="uploadFile('dvlaFile')" class="btn btn-primary" type="button">Browse</button>
                    <button *ngIf="customer.dvlaFile != null" (click)="clearFile('dvlaFile')" class="btn btn-danger"
                        type="button"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Proof of Address</div>
                </div>
                <input *ngIf="customer.addressFile != null" type="text" class="form-control" id="addressFile"
                    name="addressFile" value="{{ customer.addressFile.name() }}" disabled>
                <input *ngIf="customer.addressFile == null" type="text" class="form-control" id="addressFile"
                    name="addressFile" value="Not Available" disabled>
                <div class="input-group-append">
                    <button *ngIf="customer.addressFile != null" (click)="downloadFile('addressFile')"
                        class="btn btn-success" type="button">Download</button>
                    <button (click)="uploadFile('addressFile')" class="btn btn-primary" type="button">Browse</button>
                    <button *ngIf="customer.addressFile != null" (click)="clearFile('addressFile')"
                        class="btn btn-danger" type="button"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </div>
    </div>

    <div class="form-row mb-3">
        <div class="col">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">Agreement</div>
                </div>
                <input *ngIf="customer.agreementFile != null" type="text" class="form-control" id="agreementFile"
                    name="agreementFile" value="{{ customer.agreementFile.name() }}" disabled>
                <input *ngIf="customer.agreementFile == null" type="text" class="form-control" id="agreementFile"
                    name="agreementFile" value="Not Available" disabled>
                <div class="input-group-append">
                    <button *ngIf="customer.agreementFile != null" (click)="downloadFile('agreementFile')"
                        class="btn btn-success" type="button">Download</button>
                    <button (click)="uploadFile('agreementFile')" class="btn btn-primary" type="button">Browse</button>
                    <button *ngIf="customer.agreementFile != null" (click)="clearFile('agreementFile')"
                        class="btn btn-danger" type="button"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>