<div class="modal-header">
        <h4 class="modal-title">Vehicle</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="card">
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="navbar-brand" href="#">Vehicles</div>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav mr-auto">
    
                            </ul>
                            <form class="form-inline my-2 my-lg-0">
                                <input [(ngModel)]="filterString" class="form-control mr-sm-2" type="search" placeholder="Search by Registration" aria-label="Search" id="filterString" name="filterString">
                                <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="filterVehicleByRegistration(filterString)">Search</button>
                            </form>
                        </div>
                    </nav>
                    <div class="card-body">
                        <list-vehicles [vehicles]="vehicles" (clicked)="rowClicked($event)"></list-vehicles>
                    </div>
                    <div class="card-footer text-muted">
                        {{ vehicles.length }} results shown.
                    </div>
                </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cancel')">Cancel</button>
    </div>