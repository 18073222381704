import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'list-payments',
    template:
        `
        <table id="notes-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
        <thead>
            <tr>
                <th>Object ID</th>
                <th>Due Date</th>
                <th>Customer</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let payment of payments" (click)="rowClicked(payment)">
                <td>{{ payment.id }}</td>
                <td *ngIf="payment.dueDate == null"></td>
                <td *ngIf="payment.dueDate != null">{{ payment.dueDate.year }}-{{ payment.dueDate.month }}-{{ payment.dueDate.day }}</td>
                <td>{{ payment.customer.firstName }} {{ payment.customer.lastName }}</td>
                <td>{{ payment.status }}</td>
            </tr>
        </tbody>
    </table>
    `
})
export class ListPaymentsComponent implements OnInit {
    @Input() public payments;
    @Output() clicked = new EventEmitter<any>();

    ngOnInit() { }

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }
}