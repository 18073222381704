import { CommonModule } from '@angular/common';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { ChartsModule } from 'ng2-charts';

import { ParseService } from './services/parse.service';
import { VehicleService } from './services/vehicle.service';
import { CustomerService } from './services/customer.service';
import { ContractService } from './services/contract.service';
import { AgreementService } from './services/agreement.service';
import { NoteService } from './services/note.service';
import { PaymentService } from './services/payment.service';
import { ReservationService } from './services/reservation.service';
import { PartService } from './services/part.service';

import { NgbdModalBasic } from './backoffice/modals/basic/basic.component';
import { NgxPopper } from 'angular-popper';
import { ProspectService } from './services/prospect.service';
import { TaskService } from './services/task.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material/material.module';
import { StatComponent } from './backoffice/components/stat/stat.component';
import { BackofficeModule } from './backoffice/backoffice.module';
import { SharedModule } from './modules/shared/shared.module';
import { DebtService } from './services/debt.service';

@NgModule({
  declarations: [
    AppComponent,
    NgbdModalBasic,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgxPopper,
    BrowserAnimationsModule,
    MaterialModule,
    ChartsModule,
    BackofficeModule,
    SharedModule,
    HammerModule,
  ],
  entryComponents: [
    NgbdModalBasic,
    StatComponent,
  ],
  providers: [
    ParseService,
    VehicleService,
    CustomerService,
    ContractService,
    AgreementService,
    PaymentService,
    NoteService,
    ProspectService,
    ReservationService,
    TaskService,
    PartService,
    AuthGuard,
    DebtService,
  ],
  bootstrap: [AppComponent],
  exports: [
    NgbdModalBasic,
    MaterialModule,
  ]
})
export class AppModule { }
