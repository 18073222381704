import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'list-vehicles',
    template:
    `
    <table id="notes-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
    <thead>
        <tr>
            <th>Object ID</th>
            <th>Vehicle ID</th>
            <th>Registration</th>
            <th>Make</th>
            <th>Model</th>
            <th>ITV Date</th>
            <th>ITV Booking Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let vehicle of vehicles" (click)="rowClicked(vehicle)">
            <td>{{ vehicle.id }}</td>
            <td>{{ vehicle.vehicleID }}</td>
            <td>{{ vehicle.registration }}</td>
            <td>{{ vehicle.make }}</td>
            <td>{{ vehicle.model }}</td>
            <td>
                {{ 
                    vehicle.itvDate 
                    ? vehicle.itvDate.day + '-' + vehicle.itvDate.month + '-' + vehicle.itvDate.year 
                    : 'NaN' 
                }}
            </td>
            <td>
                {{ 
                    vehicle.itvBookingDate 
                    ? vehicle.itvBookingDate.day + '-' + vehicle.itvBookingDate.month + '-' + vehicle.itvBookingDate.year 
                    : 'NaN' 
                }}
            </td>
        </tr>
    </tbody>
    </table>
    `
})
export class ListVehiclesComponent implements OnInit {

    @Input() public vehicles = [];
    @Output() clicked = new EventEmitter<any>();

    ngOnInit() { }

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }

}