import { Customer } from './customer';
import { Vehicle } from './vehicle';
import { Agreement } from './agreement';

export class Contract {
    id: string = null;
    customer: Customer = null;
    vehicle: Vehicle = null;
    agreement: Agreement = null;
    status: any = undefined; // Draft, Pending, Active, Ended, Cancelled.
    additionalDrivers: Array<Customer> = [];
    notes: String = null;
    contractType: any = undefined;
    paymentType: any = undefined;
}