import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'form-note',
    templateUrl: './form-note.component.html'
})
export class FormNoteComponent implements OnInit {

    public noteStatuses: Array<string> = ["Open", "Closed"];
    @Input() public note: any;

    constructor() {}

    ngOnInit() {}

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }

        return optionOne === optionTwo;
    }

}