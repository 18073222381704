import { Injectable } from '@angular/core';
import { ParseService } from './parse.service';
import { Prospect } from '../backoffice/models/propect';
import { CustomerService } from './customer.service';
import { Customer } from '../backoffice/models/customer';

@Injectable()
export class ProspectService {

    constructor(
        private parseService: ParseService,
        private customerService: CustomerService,
    ) { }

    async fromParseObject(obj) {
        let prospect: Prospect = new Prospect();
        prospect.id = obj.id;
        prospect.firstName = obj.get("firstName");
        prospect.lastName = obj.get("lastName");
        prospect.company = obj.get("company");
        prospect.serviceNotes = obj.get("serviceNotes");
        prospect.status = obj.get("status");
        prospect.telephone = obj.get("telephone");
        prospect.email = obj.get("email");
        prospect.preferedContact = obj.get("preferedContact");
        prospect.followUp = obj.get("followUp");
        prospect.leadSource = obj.get("leadSource");
        prospect.customerNumber = obj.get("customerNumber");
        prospect.assignee = obj.get("assignee");

        if (obj.get("customer") != null) {
            let tmp = await obj.get("customer").fetch();
            prospect.customer = await this.customerService.fromParseObject(tmp);
        }

        if (obj.get("contactDate") != null) {
            prospect.contactDate = this.parseService.toNgbDate(obj.get("contactDate"));
        } else {
            prospect.contactDate = obj.get("contactDate");
        }

        if (obj.get("paidCustomerDate") != null) {
            prospect.paidCustomerDate = this.parseService.toNgbDate(obj.get("paidCustomerDate"));
        } else {
            prospect.paidCustomerDate = obj.get("paidCustomerDate");
        }

        return prospect;
    }

    toParseObject(prospect: Prospect) {
        let obj = this.parseService.createParseInstance("Prospect");
        obj.id = prospect.id;
        obj.set("firstName", prospect.firstName);
        obj.set("lastName", prospect.lastName);
        obj.set("company", prospect.company);
        obj.set("serviceNotes", prospect.serviceNotes);
        obj.set("status", prospect.status);
        obj.set("telephone", prospect.telephone);
        obj.set("email", prospect.email);
        obj.set("preferedContact", prospect.preferedContact);
        obj.set("followUp", prospect.followUp);
        obj.set("leadSource", prospect.leadSource);
        obj.set("customerNumber", prospect.customerNumber);
        obj.set("assignee", prospect.assignee);

        if (prospect.customer != null) {
            let customer = this.parseService.createParseInstance("Customer");
            customer.id = prospect.customer.id
            obj.set("customer", customer);
        }

        if (prospect.contactDate != null) {
            obj.set("contactDate", this.parseService.fromNgbDate(prospect.contactDate));
        } else {
            obj.set("contactDate", prospect.contactDate);
        }

        if (prospect.paidCustomerDate != null) {
            obj.set("paidCustomerDate", this.parseService.fromNgbDate(prospect.paidCustomerDate));
        } else {
            obj.set("paidCustomerDate", prospect.paidCustomerDate);
        }

        return obj;
    }

    saveProspect(obj: Prospect): Promise<any> {
        return new Promise(async (resolve, reject) => {

            try {
                var prospect = await this.toParseObject(obj);
            } catch(error) {
                reject(error);
                return;
            }

            prospect.save().then(async (result) => {
                resolve(await this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })

        })
    }

    getAllProspects(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Prospect = this.parseService.createParseSubclass("Prospect");
            let query = this.parseService.createParseQuery(Prospect);
            let prospects = [];

            query.limit(1000);
            query.descending("contactDate");
            query.find().then(async (results) => {
                for (var i in results) {
                    prospects.push(await this.fromParseObject(results[i]));
                }
                resolve(prospects)
            }, (error) => {
                reject(error);
            })
        })
    }

    getOpenProspects(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Prospect = this.parseService.createParseSubclass("Prospect");
            let query = this.parseService.createParseQuery(Prospect);
            let prospects = [];

            query.notEqualTo("status", "Dead Lead");

            query.limit(1000);
            query.descending("contactDate");
            query.find().then(async (results) => {
                for (var i in results) {
                    prospects.push(await this.fromParseObject(results[i]));
                }
                resolve(prospects)
            }, (error) => {
                reject(error);
            })
        })
    }

    getProspectById(objectID): Promise<any> {
        return new Promise((resolve, reject) => {
            let Prospect = this.parseService.createParseSubclass("Prospect");
            let query = this.parseService.createParseQuery(Prospect);

            query.get(objectID).then(async (result) => {
                resolve(this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getProspectsByStatus(status): Promise<any> {
        return new Promise((resolve, reject) => {
            let Prospect = this.parseService.createParseSubclass("Prospect");
            let query = this.parseService.createParseQuery(Prospect);
            let prospects = [];

            query.equalTo("status", status);
            query.descending("contactDate");
            query.find().then(async (results) => {
                for (var i in results) {
                    prospects.push(await this.fromParseObject(results[i]));
                }
                resolve(prospects)
            }, (error) => {
                reject(error);
            })
        })
    }

    getProspectsByStatusAndCompany(status, company): Promise<any> {
        return new Promise((resolve, reject) => {
            let Prospect = this.parseService.createParseSubclass("Prospect");
            let query = this.parseService.createParseQuery(Prospect);
            let prospects = [];

            if (status != null) {
                query.equalTo("status", status);
            }

            if (company != null) {
                query.equalTo("company", company);
            }

            query.descending("contactDate");
            query.find().then(async (results) => {
                for (var i in results) {
                    prospects.push(await this.fromParseObject(results[i]));
                }
                resolve(prospects)
            }, (error) => {
                reject(error);
            })
        })
    }

    filterByLastName(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            let Prospect = this.parseService.createParseSubclass("Prospect");
            let query = this.parseService.createParseQuery(Prospect);
            let prospects = [];

            query.matches("lastName", filter, 'i');
            query.descending("contactDate");
            query.find().then(async (results) => {
                for (var i in results) {
                    prospects.push(await this.fromParseObject(results[i]));
                }
                resolve(prospects)
            }, (error) => {
                reject(error);
            })
        })
    }

    getSubscription() {
        var Prospect = this.parseService.createParseSubclass("Prospect");
        var query = this.parseService.createParseQuery(Prospect);
        let subscription = query.subscribe();
        return subscription;
    }

}