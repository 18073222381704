<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Object ID:</div>
            </div>
            <input [(ngModel)]="reservation.id" type="text" class="form-control" placeholder="New reservation" id="id"
                name="id" disabled>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Customer:</div>
            </div>
            <input [(ngModel)]="customerText" type="text" class="form-control" placeholder="Select Customer"
                id="customer" name="customer" disabled>
            <div class="input-group-append">
                <button (click)="selectCustomerButton()" class="btn btn-primary" type="button"><i
                        class="fa fa-user"></i></button>
            </div>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Size:</div>
            </div>
            <select [(ngModel)]="reservation.size" [compareWith]="compareFn" class="form-control" id="size" name="size">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let size of vehicleSizes" [ngValue]="size">{{ size }}</option>
            </select>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Transmission:</div>
            </div>
            <select [(ngModel)]="reservation.transmission" [compareWith]="compareFn" class="form-control"
                id="transmission" name="transmission">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let transmission of vehicleTransmissions" [ngValue]="transmission">{{ transmission }}
                </option>
            </select>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Vehicle Assigned:</div>
            </div>
            <input [(ngModel)]="vehicleText" type="text" class="form-control" placeholder="TBA" id="vehicle"
                name="vehicle" value="TBA" disabled>
            <div class="input-group-append">
                <button (click)="selectVehicleButton()" class="btn btn-primary" type="button"><i
                        class="fa fa-car"></i></button>
                <button (click)="removeVehicleButton()" class="btn btn-danger" type="button"><i
                        class="fa fa-trash"></i></button>
            </div>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">Collection Date</div>
            </div>
            <input [(ngModel)]="reservation.dateTime" class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker
                #dateTime="ngbDatepicker" id="dateTime" name="dateTime">
            <button class="input-group-addon" (click)="dateTime.toggle()" type="button">
                <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                <span class="fa fa-calendar"></span>
            </button>
        </div>
    </div>

    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Number of Extra Drivers:</div>
            </div>
            <input [(ngModel)]="reservation.extraDrivers" type="text" class="form-control" id="extraDrivers"
                name="extraDrivers">
        </div>
    </div>

    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Transfer Required:</div>
            </div>
            <select [(ngModel)]="reservation.location" [compareWith]="compareFn" class="form-control" id="location"
                name="location">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let location of trasferLocations" [ngValue]="location">{{ location }}
                </option>
            </select>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Notes:</div>
            </div>

            <textarea [(ngModel)]="reservation.notes" class="form-control" rows="8" id="notes"></textarea>

        </div>
    </div>
</div>

<div class="form-group row">
    <div class="col-sm-2">Documents Received: </div>
    <div class="col-sm-10">
        <div class="form-check">
            <input [(ngModel)]="reservation.docsRecieved" class="form-check-input" type="checkbox" id="docsRecieved">
        </div>
    </div>
</div>

<div class="form-group row">
    <div class="col-sm-2">Extra Drivers: </div>
    <div class="col-sm-10">
        <div class="form-check">
            <input [(ngModel)]="reservation.youngDriver" class="form-check-input" type="checkbox" id="youngDriver">
        </div>
    </div>
</div>

<div class="form-group row">
    <div class="col-sm-2">Deposit Received: </div>
    <div class="col-sm-10">
        <div class="form-check">
            <input [(ngModel)]="reservation.depositReceived" class="form-check-input" type="checkbox"
                id="depositReceived">
        </div>
    </div>
</div>

<div class="form-group row">
    <div class="col-sm-2">Complete: </div>
    <div class="col-sm-10">
        <div class="form-check">
            <input [(ngModel)]="reservation.complete" class="form-check-input" type="checkbox" id="complete">
        </div>
    </div>
</div>