<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Object ID:</div>
            </div>
            <input [(ngModel)]="note.id" type="text" class="form-control" placeholder="Note ID" id="id" name="id" disabled>
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Title:</div>
            </div>
            <input [(ngModel)]="note.title" type="text" class="form-control" placeholder="Title" id="Title" name="Title">
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Note:</div>
            </div>
            <input [(ngModel)]="note.content" type="text" class="form-control" placeholder="Content" id="Content" name="Content">
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Status:</div>
            </div>
            <select [(ngModel)]="note.status" [compareWith]="compareFn" class="form-control" id="Status"
                name="Status">
                <option value="null" disabled selected>Please Select</option>
                <option *ngFor="let status of noteStatuses" [ngValue]="status">{{ status }}</option>
            </select>
        </div>
    </div>
</div>