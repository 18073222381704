<h4>Agreement Information</h4>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Agreement ID:</div>
            </div>
            <input [(ngModel)]="agreement.id" type="text" class="form-control" placeholder="Agreement ID" id="id" name="id" disabled>
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">Start Date:</div>
            </div>
            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="agreement.startDate" ngbDatepicker
                #startDate="ngbDatepicker" id="startDate" name="startDate">
            <button class="input-group-addon" (click)="startDate.toggle()" type="button">
                <span class="fa fa-calendar"></span>
            </button>
        </div>

    </div>
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">End Date:</div>
            </div>
            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="agreement.endDate" ngbDatepicker #endDate="ngbDatepicker"
                id="endDate" name="endDate">
            <button class="input-group-addon" (click)="endDate.toggle()" type="button">
                <span class="fa fa-calendar"></span>
            </button>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Payment Day:</div>
            </div>
            <input [(ngModel)]="agreement.paymentDay" type="text" class="form-control" placeholder="Payment Day" id="paymentDay"
                name="paymentDay">
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Rental Amount:</div>
            </div>
            <input [(ngModel)]="agreement.rentalAmount" type="text" class="form-control" placeholder="Rental Amount" id="rentalAmount"
                name="rentalAmount">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Deposit Amount:</div>
            </div>
            <input [(ngModel)]="agreement.depositAmount" type="text" class="form-control" placeholder="Deposit Amount"
                id="depositAmount" name="depositAmount">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Extras Amount:</div>
            </div>
            <input [(ngModel)]="agreement.extrasAmount" type="text" class="form-control" placeholder="Extras Amount" id="extrasAmount"
                name="extrasAmount">
        </div>
    </div>
</div>