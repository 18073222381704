import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class Vehicle {
    id: string = null;
    vehicleID: string = null;
    registration: string = null;
    make: string = null;
    model: string = null;
    colour: string = null;
    year: number = null;
    mileage: number = null;
    itvDate: NgbDate = null;
    itvBookingDate: NgbDate = null;
    serviceDate: NgbDate = null;
    insuranceExpieryDate: NgbDate = null;
    price: number = null;  // Rental Price.
    purchasePrice: number = null; // Purchase Price.
    salePrice: number = null // Sale Price
    status: string = undefined; // Rented, Available, Courtesy, Sale, Other.
    registraionStatus: string = undefined; // Rental, Non-Rental.
    purchaseDate: NgbDate = null;
    itvStatus: string;
    location: string = undefined;
    repairerName: string = null;
    estimatedCompleteDate: NgbDate = null;
    vehicleToRepairerDate: NgbDate = null;
    notes: Array<any> = null;
    screenHide: boolean = false;
    image: any = {_url: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png"};
    permissoFile: any = null;
    insuranceFile: any = null;
    fichaFrontFile: any = null;
    fichaBackFile: any = null;
    greenCardFile: any = null;
    brand: string = null;
}
