import { Injectable } from '@angular/core';
import { ParseService } from './parse.service';
import { Customer } from '../backoffice/models/customer';
import { NoteService } from './note.service';

@Injectable()
export class CustomerService {

    constructor(
        private parseService: ParseService,
        private noteService: NoteService,
    ) { }

    async fromParseObject(obj) {
        let customer: Customer = new Customer();
        customer.id = obj.id;
        customer.customerID = obj.get("customerID");
        customer.firstName = obj.get("firstName");
        customer.lastName = obj.get("lastName");
        customer.address = obj.get("address");
        customer.city = obj.get("city");
        customer.country = obj.get("country");
        customer.postCode = obj.get("postCode");
        customer.contactNumber = obj.get("contactNumber");
        customer.contactEMail = obj.get("contactEMail");
        customer.nie = obj.get("nie");
        customer.licenseNumber = obj.get("licenseNumber");
        customer.customerType = obj.get("customerType");
        customer.notes = obj.get("notes");
        customer.licenceFile = obj.get("licenceFile");
        customer.passportFile = obj.get("passportFile");
        customer.dvlaFile = obj.get("dvlaFile");
        customer.addressFile = obj.get("addressFile");
        customer.agreementFile = obj.get("agreementFile");

        if (obj.get("birthDate") != null) {
            customer.birthDate = this.parseService.toNgbDate(obj.get("birthDate"));
        } else {
            customer.birthDate = obj.get("birthDate");
        }

        if (obj.get("licenseExpiryDate") != null) {
            customer.licenseExpiryDate = this.parseService.toNgbDate(obj.get("licenseExpiryDate"));
        } else {
            customer.licenseExpiryDate = obj.get("licenseExpiryDate");
        }

        if ( Array.isArray(obj.get("notes")) ) {
            let notes = [];

            for ( var note of obj.get("notes") ) {
                notes.push( await this.noteService.fromParseObject(note));
            }
            customer.notes = notes;
        } else {
            customer.notes = [];
        }

        return customer;
    }

    async toParseObject(customer: Customer) {
        let obj = this.parseService.createParseInstance("Customer");
        obj.id = customer.id;
        obj.set("customerID", customer.customerID);
        obj.set("firstName", customer.firstName);
        obj.set("lastName", customer.lastName);
        obj.set("address", customer.address);
        obj.set("city", customer.city);
        obj.set("country", customer.country);
        obj.set("postCode", customer.postCode);
        obj.set("contactNumber", customer.contactNumber);
        obj.set("contactEMail", customer.contactEMail);
        obj.set("nie", customer.nie);
        obj.set("licenseNumber", customer.licenseNumber);
        obj.set("customerType", customer.customerType);
        obj.set("licenceFile", customer.licenceFile);
        obj.set("passportFile", customer.passportFile);
        obj.set("dvlaFile", customer.dvlaFile);
        obj.set("addressFile", customer.addressFile);
        obj.set("agreementFile", customer.agreementFile);


        if (customer.birthDate != null) {
            obj.set("birthDate", this.parseService.fromNgbDate(customer.birthDate));
        } else {
            obj.set("birthDate", customer.birthDate);
        }

        if (customer.licenseExpiryDate != null) {
            obj.set("licenseExpiryDate", this.parseService.fromNgbDate(customer.licenseExpiryDate));
        } else {
            obj.set("licenseExpiryDate", customer.licenseExpiryDate);
        }

        if ( Array.isArray(customer.notes) ) {
            let notes = [];
            for ( var note of customer.notes) {
                let obj = await this.noteService.toParseObject(note);
                notes.push(obj);
            }
            obj.set("notes", notes);
        } else {
            obj.set("notes", []);
        }

        return obj;
    }

    saveCustomer(obj: Customer): Promise<any> {
        return new Promise(async (resolve, reject) => {

            try {
                var customer = await this.toParseObject(obj);
            } catch (error) {
                reject(error);
                return;
            }
            
            customer.save().then(async (result) => {
                resolve( await this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getCustomerByID(objectID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let Customer = this.parseService.createParseSubclass("Customer");
            let query = this.parseService.createParseQuery(Customer);

            query.include("notes");
            query.get(objectID).then( async (result) => {
                resolve(this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getAllCustomers(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Customer = this.parseService.createParseSubclass("Customer");
            let query = this.parseService.createParseQuery(Customer);
            let customers = [];

            query.include("notes");
            query.limit(1000);
            query.ascending("lastName");
            query.find().then( async (results) => {
                for ( var result of results ) {
                    customers.push( await this.fromParseObject(result));
                }
                resolve(customers);
            }, (error) => {
                reject(error);
            })
        })
    }

    searchCustomersByLastName(lastName: any) {
        return new Promise((resolve, reject) => {
            let Customer = this.parseService.createParseSubclass("Customer");
            let query = this.parseService.createParseQuery(Customer);
            let customers = [];

            query.include("notes");
            query.limit(1000);
            query.ascending("lastName");
            query.matches("lastName", lastName, 'i');
            query.find().then( async (results) => {
                for ( var result of results ) {
                    customers.push( await this.fromParseObject(result));
                }
                resolve(customers);
            }, (error) => {
                reject(error);
            })
        })
    }



}