import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BackofficeComponent } from './backoffice.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TestComponent } from './pages/test/test.component';
import { ListDebtsComponent } from './pages/list-debts/list-debts.component';
import { EditDebtsComponent } from './pages/edit-debts/edit-debts.component';

const routes: Routes = [
    {
        path: '',
        component: BackofficeComponent,
        children: [
            { path: '',  component: DashboardComponent },
            { path: 'contract', loadChildren: () => import('./pages/contract/contract.module').then(m => m.ContractModule) },
            { path: 'customer', loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule) },
            { path: 'parts', loadChildren: () => import('./pages/part/parts.module').then(m => m.PartsModule) },
            { path: 'payment',  loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule) },
            { path: 'prospect',  loadChildren: () => import('./pages/prospect/prospect.module').then(m => m.ProspectModule) },
            { path: 'reservation',  loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationModule) },
            { path: 'report', loadChildren: () => import('./reports/reports.module').then(m => m.CsvReportsModule)},
            { path: 'reports', loadChildren: () => import('./pages/csvreports/csvreports.module').then(m => m.CsvReportsModule)},
            { path: 'stripe', loadChildren: () => import('./pages/stripe/stripe.module').then(m => m.StripeModule) },
            { path: 'vehicle',  loadChildren: () => import('./pages/vehicle/vehicle.module').then(m => m.VehicleModule) },
            { path: 'task', loadChildren: () => import('./pages/task/task.module').then(m => m.TaskModule) },


            { path: 'list-debts', component: ListDebtsComponent },
            { path: 'edit-debts', component: EditDebtsComponent },
            { path: 'edit-debts/:objectID', component: EditDebtsComponent },

            { path: 'test',     component: TestComponent },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BackofficeRoutingModule { }
