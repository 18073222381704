import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { Contract } from './contract';
import { Customer } from './customer';
import { Vehicle } from './vehicle';

export class Payment {
    id: string = null;
    amount: any = null;
    dueDate: NgbDate = null;
    paidDate: NgbDate = null;
    status: string = null; // Due, Paid, Canceled.
    contract: Contract = null;
    customer: Customer = null;
    vehicle: Vehicle = null;
}