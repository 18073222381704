import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbDropdownModule, NgbCarouselModule, NgbAlertModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BackofficeRoutingModule } from './backoffice-routing.module';

import { BackofficeComponent } from './backoffice.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';

import { TestComponent } from './pages/test/test.component';

import { ListVehiclesComponent } from './components/list-vehicles/list-vehicles.component';
import { ListCustomersComponent } from './components/list-customers/list-customers.component';
import { ListContractsComponent } from './components/list-contracts/list-contracts.component';
import { ListNotesComponent } from './components/list-notes/list-notes.component';
import { ListPaymentsComponent} from './components/list-payments/list-payments.component';
import { ListProspectsComponent} from './components/list-prospects/list-prospects.component'

import { FormVehicleComponent } from './components/form-vehicle/form-vehicle.component';
import { FormNoteComponent } from './components/form-note/form-note.component';
import { FormCustomerComponent } from './components/form-customer/form-customer.component';
import { FormContractComponent } from './components/form-contract/form-contract.component';
import { FormAgreementComponent } from './components/form-agreement/form-agreement.component'
import { FormDriversComponent } from './components/form-drivers/form-drivers.component';
import { FormPaymentComponent } from './components/form-payment/form-payment.component';
import { FormProspectComponent } from './components/form-prospect/form-prospect.component';

import { SelectVehicleComponent } from './components/select-vehicle/select-vehicle.component';

import { NgbdModalNoteForm } from './modals/note-form/note-form.component';
import { NgbdModalPaymentForm } from './modals/payment-form/payment-form.component';
import { NgbdModalCustomerSelect } from './modals/customer-select/customer-select.component';
import { NgbdModalVehicleSelect } from './modals/vehicle-select/vehicle-select.component';
import { NgbdModalNoteSelect } from './modals/note-select/note-select.component';
import { NgbdModalPaymentSelect } from './modals/payment-select/payment-select.component';
import { FormReservationComponent } from './components/form-reservation/form-reservation.component';
import { ListReservations } from './components/list-reservations/list-reservations.component';
import { ListTasksComponent } from './components/list-tasks/list-tasks.component';
import { FromTaskComponent } from './components/form-tasks/form-task.component';
import { ListDebtsComponent } from './pages/list-debts/list-debts.component';
import { EditDebtsComponent } from './pages/edit-debts/edit-debts.component';
import { MaterialModule } from '../modules/material/material.module';

import { StatComponent } from './components/stat/stat.component';
import { SharedModule } from '../modules/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgbDropdownModule,
        NgbCarouselModule,
        NgbAlertModule,
        BackofficeRoutingModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule,
    ],
    entryComponents: [
        NgbdModalCustomerSelect,
        NgbdModalVehicleSelect,
        NgbdModalNoteSelect,
        NgbdModalPaymentSelect,
        NgbdModalNoteForm,
        NgbdModalPaymentSelect,
        NgbdModalPaymentForm,
        StatComponent
    ],
    declarations: [
        TestComponent,
        BackofficeComponent,
        DashboardComponent,
        SidebarComponent,
        HeaderComponent,
        FormVehicleComponent,
        FormNoteComponent,
        FormCustomerComponent,
        FormContractComponent,
        FormAgreementComponent,
        FormPaymentComponent,
        FormProspectComponent,
        FormReservationComponent,
        FromTaskComponent,
        ListVehiclesComponent,
        ListNotesComponent,
        ListCustomersComponent,
        ListContractsComponent,
        ListPaymentsComponent,
        ListProspectsComponent,
        ListReservations,
        ListTasksComponent,
        FormDriversComponent,
        SelectVehicleComponent,
        NgbdModalCustomerSelect,
        NgbdModalVehicleSelect,
        NgbdModalNoteSelect,
        NgbdModalNoteForm,
        NgbdModalPaymentSelect,
        NgbdModalPaymentForm,
        ListDebtsComponent,
        EditDebtsComponent,
    ],
    providers: [
        NgbActiveModal,
        FormBuilder,
    ],
    exports: [
        FormVehicleComponent,
        FormNoteComponent,
        FormCustomerComponent,
        FormContractComponent,
        FormAgreementComponent,
        FormDriversComponent,
        FormPaymentComponent,
        FormProspectComponent,
        FormReservationComponent,
        FromTaskComponent,
        ListVehiclesComponent,
        ListNotesComponent,
        ListCustomersComponent,
        ListContractsComponent,
        ListPaymentsComponent,
        ListProspectsComponent,
        ListReservations,
        ListTasksComponent,
        SelectVehicleComponent,
        NgbdModalCustomerSelect,
        NgbdModalVehicleSelect,
        NgbdModalPaymentSelect,
        NgbdModalPaymentForm,
    ]
})
export class BackofficeModule {}
