<nav class="navbar navbar-expand-lg fixed-top">
    <a class="navbar-brand" href="#">{{ env.appName }}</a>
    <button class="navbar-toggler" type="button" (click)="toggleSidebar()">
        <!-- <span class="navbar-toggler-icon"></span> -->
        <i class="fa fa-bars text-muted" aria-hidden="true"></i>
    </button>
    <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown" ngbDropdown>
                <a href="javascript:void(0)" class="nav-link" ngbDropdownToggle>
                    <i class="fa fa-user"></i> {{ currentUser.get("email") }} <b class="caret"></b>
                </a>
                <div class="dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0)">
                        <i class="fa fa-fw fa-user"></i> {{ 'Profile' }}
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                        <i class="fa fa-fw fa-envelope"></i> {{ 'Inbox' }}
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)">
                        <i class="fa fa-fw fa-gear"></i> {{ 'Settings' }}
                    </a>
                    <a class="dropdown-item" (click)="showVersion()">
                        <i class="fa fa-fw fa-gear"></i> {{ 'About' }}
                    </a>
                    <a class="dropdown-item" (click)="onLoggedout()">
                        <i class="fa fa-fw fa-power-off"></i> {{ 'Log Out' }}
                    </a>
                </div>
            </li>
        </ul>
    </div>
</nav>