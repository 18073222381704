import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'select-vehicle',
    template:
    `
    <table id="vehicle-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
    <thead>
        <tr>
            <th>Vehicle ID</th>
            <th>Registration</th>
            <th>Make</th>
            <th>Model</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let vehicle of vehicles" (click)="rowClicked(vehicle)">
            <td>{{ vehicle.id }}</td>
            <td>{{ vehicle.registration }}</td>
            <td>{{ vehicle.make }}</td>
            <td>{{ vehicle.model }}</td>
        </tr>
    </tbody>
    </table>
    `
})
export class SelectVehicleComponent implements OnInit {

    public vehicles: Array<any> = [];

    @Input() public vehicleList: any;
    @Output() vehicleSelected = new EventEmitter<string>();

    constructor( ) { }

    ngOnInit() { }

    removeAdditionalDriver(vehicle) {
        this.vehicleSelected.next(vehicle);
    }

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }
        
        return optionOne === optionTwo;
    }

}