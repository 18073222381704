import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'form-drivers',
    templateUrl: './form-drivers.component.html'
})
export class FormDriversComponent implements OnInit {

    @Input() public drivers: any;
    @Output() customerSelected = new EventEmitter<string>();

    constructor( ) { }

    ngOnInit() { }

    removeAdditionalDriver(customer) {
        this.customerSelected.next(customer);
    }

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }
        
        return optionOne === optionTwo;
    }

}