import { Injectable } from "@angular/core";
import { ParseService } from './parse.service';
import { Task } from '../backoffice/models/task';
import { resolve, reject } from 'q';
import { waitForAsync } from '@angular/core/testing';

@Injectable()
export class TaskService {

    constructor(
        private parseService: ParseService,
    ) { }

    async fromParseObject(obj) {
        let task: Task = new Task();
        task.id = obj.id;
        task.description = obj.get("description");
        task.link = obj.get("link");
        task.company = obj.get("company");
        task.status = obj.get("status");
        task.assignedTo = obj.get("assignedTo");

        if ( task.assignedTo == undefined ) {
            task.assignedTo = undefined;
        } else {
            task.assignedTo = {
                id: obj.get("assignedTo").id,
                username: obj.get("assignedTo").get("username"),
            }
        }

        if (obj.get("actionDate") != null) {
            task.actionDate = this.parseService.toNgbDate(obj.get("actionDate"));
        } else {
            task.actionDate = obj.get("actionDate");
        }

        return task;
    }

    async fromParseObjectLoop(tasks: any) {
        let newTasks = [];
        for (var task of tasks) {
            newTasks.push(await this.fromParseObject(task))
        }
        return newTasks;
    }

    getAllTasks(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.parseService.parseCloudRun("getAllTasks").then(
                async (tasks) => {
                    resolve(await this.fromParseObjectLoop(tasks));
                }, (error) => {
                    reject(error);
                }
            );
        });
    }

    getTaskById(objectID): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.parseService.parseCloudRun("getTaskById", { id: objectID }).then(
                async (task) => {
                    resolve(await this.fromParseObject(task));
                }, (error) => {
                    reject(error);
                }
            );
        });
    }

    saveTask(task: Task): Promise<any> {
        if ( task.assignedTo.id !== undefined ) {
            task.assignedTo = {
                    "__type": "Pointer",
                    "className": "_User",
                    "objectId": task.assignedTo.id,
                  }
        } else {
            task.assignedTo = undefined;
        }

        return new Promise(async (resolve, reject) => {
            await this.parseService.parseCloudRun("saveTask", { task: {
                id: task.id,
                description: task.description,
                link: task.link,
                actionDate: this.parseService.fromNgbDate(task.actionDate),
                company: task.company,
                status: task.status,
                assignedTo: task.assignedTo
            } }).then(
                async (result) => {
                    resolve(await this.fromParseObject(result));
                }, (error) => {
                    reject(error);
                }
            );
        });
    }

    getTasksByStatusAndCompany(status, company): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.parseService.parseCloudRun("getTaskByStatusAndCompany", { status: status, company: company }).then(
                async (tasks) => {
                    resolve(await this.fromParseObjectLoop(tasks));
                }, (error) => {
                    reject(error);
                }
            );
        });
    }

    getAllUsers(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await this.parseService.parseCloudRun("getAllUsers").then(
                async (users) => {
                    resolve(users);
                }, (error) => {
                    reject(error);
                }
            )
        })
    }

    getSubscription() {
        var Task = this.parseService.createParseSubclass("Task");
        var query = this.parseService.createParseQuery(Task);
        let subscription = query.subscribe();
        return subscription;
    }
}