<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Amount &euro;:</div>
            </div>
            <input [(ngModel)]="payment.amount" type="text" class="form-control" placeholder="Amount" id="amount"
                name="amount">
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">Due Date</div>
            </div>
            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="payment.dueDate" ngbDatepicker #dueDate="ngbDatepicker"
                id="dueDate" name="dueDate">
            <button class="input-group-addon" (click)="dueDate.toggle()" type="button">
                <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                <span class="fa fa-calendar"></span>
            </button>
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">Paid Date</div>
            </div>
            <input class="form-control" placeholder="yyyy-mm-dd" [(ngModel)]="payment.paidDate" ngbDatepicker #paidDate="ngbDatepicker"
                id="paidDate" name="paidDate">
            <button class="input-group-addon" (click)="paidDate.toggle()" type="button">
                <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                <span class="fa fa-calendar"></span>
            </button>
        </div>
    </div>
</div>
<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Status:</div>
            </div>
            <select [(ngModel)]="payment.status" [compareWith]="compareFn" class="form-control" id="paymentStatus"
                name="paymentStatus">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let ptype of paymentTypes" [ngValue]="ptype">{{ ptype }}</option>
            </select>
        </div>
    </div>
</div>