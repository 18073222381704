import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class Agreement {
    id: string = null;
    startDate: NgbDate = null;
    endDate: NgbDate = null;
    rentalAmount: number = null;
    depositAmount: number = 500;
    extrasAmount: number = null;
    paymentDay: number = null;
}