<h4>Additional Drivers</h4>
<table id="customer-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
    <thead>
        <tr>
            <th style="width: 1%"></th>
            <th style="width: 1%">Customer ID</th>
            <th style="width: 1%">First Name</th>
            <th style="width: 1%">Last Name</th>
            <th style="width: 1%">Telephone</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let driver of drivers">
            <td><button (click)="removeAdditionalDriver(driver)" style="text-align: right" type="button" class="btn btn-danger btn-sm">Remove</button></td>
            <td>{{ driver.id }}</td>
            <td>{{ driver.firstName }}</td>
            <td>{{ driver.lastName }}</td>
            <td>{{ driver.contactNumber }}</td>
        </tr>
    </tbody>
</table>