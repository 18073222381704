import { Component, Input, OnInit } from '@angular/core';
import { Payment } from '../../models/payment';

@Component({
    selector: 'form-payment',
    templateUrl: './form-payment.component.html'
})
export class FormPaymentComponent implements OnInit {

    public paymentTypes: Array<string> = ["Due", "Paid", "Cancelled"];
    @Input() public payment: any;
    @Input() public contract: any;
    @Input() public customer: any;
    @Input() public vehicle: any;

    constructor() { }

    ngOnInit() {}

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }

        return optionOne === optionTwo;
    }

}