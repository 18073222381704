import { NgModule } from '@angular/core';
import { StatComponent } from 'src/app/backoffice/components/stat/stat.component';



@NgModule({
  declarations: [
    StatComponent
  ],
  exports: [
    StatComponent
  ]
})
export class SharedModule { }
