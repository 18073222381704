import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { ParseService } from './services/parse.service'

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private parseService: ParseService) {}

    canActivate() {

        if (this.parseService.getCurrentUser() ) {
            return true;
        }

        this.router.navigate(['/']);
        return false;
    }
}
