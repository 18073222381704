<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form-note [note]="note"></form-note>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveButton()">Save</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Close</button>
</div>