import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'list-tasks',
    template:
        `
        <table id="notes-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
        <thead>
            <tr>
                <th>Object ID</th>
                <th>description</th>
                <th>actionDate</th>
                <th>company</th>
                <th>status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let task of tasks" (click)="rowClicked(task)">
                <td>{{ task.id }}</td>
                <td>{{ task.description }}</td>
                <td *ngIf="task.actionDate != null">{{ task.actionDate.day }}-{{ task.actionDate.month }}-{{ task.actionDate.year }}</td>
                <td *ngIf="task.actionDate == null"></td>
                <td>{{ task.company }}</td>
                <td>{{ task.status }}</td>
            </tr>
        </tbody>
        </table>
        `
})
export class ListTasksComponent implements OnInit {
    @Input() public tasks;
    @Output() clicked = new EventEmitter

    ngOnInit() { }

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }
}