import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export class Task {
    id: string = null;
    description: String = null;
    link: String = null;
    actionDate: NgbDate = null;
    company: string = undefined; // ["Purple Cars", "Rent to Buy", "Permits", "Holidays"];
    status: string = undefined;  // Open, In Progress, Complete.
    assignedTo: any = undefined;
}