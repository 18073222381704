import { Injectable } from '@angular/core';
import { ParseService } from './parse.service';
import { Contract } from '../backoffice/models/contract';
import { CustomerService } from './customer.service';
import { VehicleService } from './vehicle.service';
import { AgreementService } from './agreement.service';
import { Agreement } from '../backoffice/models/agreement';

@Injectable()
export class ContractService {

    constructor(
        private parseService: ParseService,
        private agreementService: AgreementService,
        private customerService: CustomerService,
        private vehicleService: VehicleService,
    ) { }

    async fromParseObject(obj) {
        let contract: Contract = new Contract();
        contract.id = obj.id;
        contract.status = obj.get("status");
        contract.contractType = obj.get("contractType");
        contract.paymentType = obj.get("paymentType");
        contract.notes = obj.get("notes");
        //contract.additionalDrivers = obj.get("additionalDrivers");

        contract.agreement = obj.get("agreement");
        if (obj.get("agreement") != null) {
            let tmp = await obj.get("agreement");
            contract.agreement = await this.agreementService.fromParseObject(tmp);
        }

        if (obj.get("customer") != null) {
            let tmp = await obj.get("customer");
            contract.customer = await this.customerService.fromParseObject(tmp);
        }

        if (obj.get("vehicle") != null) {
            let tmp = await obj.get("vehicle");
            contract.vehicle = await this.vehicleService.fromParseObject(tmp);
        }

        if (Array.isArray(obj.get("additionalDrivers"))) {
            let additionalDrivers = [];

            for (var additionalDriver of obj.get("additionalDrivers")) {
                additionalDrivers.push(await this.customerService.fromParseObject(additionalDriver));
            }
            contract.additionalDrivers = additionalDrivers;
        } else {
            contract.additionalDrivers = [];
        }

        return contract;
    }

    async toParseObject(contract: Contract) {
        let obj = this.parseService.createParseInstance("Contract");
        obj.id = contract.id;
        obj.set("status", contract.status);
        obj.set("contractType", contract.contractType);
        obj.set("paymentType", contract.paymentType);
        obj.set("additionalDrivers", contract.additionalDrivers);
        obj.set("notes", contract.notes);

        if (contract.customer != null) {
            let customer = this.parseService.createParseInstance("Customer");
            customer.id = contract.customer.id
            obj.set("customer", customer);
        }

        if (contract.vehicle != null) {
            let vehicle = this.parseService.createParseInstance("Vehicle");
            vehicle.id = contract.vehicle.id
            obj.set("vehicle", vehicle);
        }

        obj.set("agreement", contract.agreement);
        if (contract.agreement != null) {
            let agreement = await this.agreementService.toParseObject(contract.agreement)
            obj.set("agreement", agreement);
        }

        if (Array.isArray(contract.additionalDrivers)) {
            let additionalDrivers = [];

            for (var additionalDriver of contract.additionalDrivers) {
                let obj = await this.customerService.toParseObject(additionalDriver);
                additionalDrivers.push(obj);
            }
            obj.set("additionalDrivers", additionalDrivers);
        } else {
            obj.set("additionalDrivers", []);
        }

        return obj;
    }

    saveContract(obj: Contract): Promise<any> {
        return new Promise(async (resolve, reject) => {

            try { 
                var contract = await this.toParseObject(obj);
            } catch (error) {
                reject(error);
                return;
            }
            
            contract.save().then((result) => {
                resolve(this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getContractByID(objectID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let Contract = this.parseService.createParseSubclass("Contract");
            let query = this.parseService.createParseQuery(Contract);

            query.include("agreement");
            query.include("customer");
            query.include("vehicle");
            query.get(objectID).then((result) => {
                resolve(this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getAllContracts() {
        return new Promise((resolve, reject) => {
            let Contract = this.parseService.createParseInstance("Contract");
            let query = this.parseService.createParseQuery(Contract);
            let contracts = [];

            query.include("agreement");
            query.include("customer");
            query.include("vehicle");
            query.limit(1000);
            query.find().then(async (results) => {
                for (var result of results) {
                    contracts.push(await this.fromParseObject(result));
                }
                resolve(contracts);
            }, (error) => {
                reject(error);
            })
        })
    }

    getContractsByStatus(status: string) {
        return new Promise((resolve, reject) => {
            let Contract = this.parseService.createParseInstance("Contract");
            let query = this.parseService.createParseQuery(Contract);
            let contracts = [];

            query.include("additionalDrivers");
            query.include("agreement");
            query.include("customer");
            query.include("vehicle");
            query.limit(1000);
            query.equalTo("status", status);
            query.find().then(async (results) => {
                for (var result of results) {
                    contracts.push(await this.fromParseObject(result));
                }
                resolve(contracts);
            }, (error) => {
                reject(error);
            })
        })
    }

    getContractsByAgreement(agreement: Agreement) {
        return new Promise(async (resolve, reject) => {
            let Contract = this.parseService.createParseInstance("Contract");
            let query = this.parseService.createParseQuery(Contract);
            let contracts = [];

            let obj = await this.agreementService.toParseObject(agreement);

            query.include("agreement");
            query.include("customer");
            query.include("vehicle");
            query.limit(1);
            query.equalTo("agreement", obj);
            query.find().then(async (results) => {
                resolve(results);
            }, (error) => {
                reject(error);
            })
        })
    }

    searchContractsByCustomerLastName(lastName: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let Customer = this.parseService.createParseSubclass("Customer");
            let customerQuery = this.parseService.createParseQuery(Customer);
            customerQuery.matches("lastName", lastName, 'i');

            let Contract = this.parseService.createParseSubclass("Contract");
            let contractQuery = this.parseService.createParseQuery(Contract);
            let contracts = [];

            contractQuery.include("agreement");
            contractQuery.include("customer");
            contractQuery.include("vehicle");
            contractQuery.matchesQuery("customer", customerQuery);
            contractQuery.find().then(async (results) => {
                for (var result of results) {
                    contracts.push(await this.fromParseObject(result));
                }
                resolve(contracts);
            }, (error) => {
                reject(error);
            })
        })
    }

    returnsReport(): Promise<any> {
        return new Promise((resolve, reject) => {
            let date = new Date();
            date.setDate(date.getDate() - 2);
            
            let Agreement = this.parseService.createParseSubclass("Agreement");
            let agreementQuery = this.parseService.createParseQuery(Agreement);
            let agreements = [];

            agreementQuery.exists("endDate");
            agreementQuery.notEqualTo("endDate", null);
            agreementQuery.greaterThanOrEqualTo("endDate", date);
            agreementQuery.ascending("endDate");
            agreementQuery.find().then(async (results) => {
                for (var result of results) {
                    agreements.push(await this.agreementService.fromParseObject(result));
                }
                resolve(agreements);
            }, (error) => {
                reject(error);
            })
        });
    }

    async getCountByStatus(status: string) {
        let Contract = this.parseService.createParseSubclass("Contract");
        let query = this.parseService.createParseQuery(Contract);
        query.equalTo("status", status);
        return await query.count();
    }

    getSubscription() {
        var Contract = this.parseService.createParseSubclass("Contract");
        var query = this.parseService.createParseQuery(Contract);
        let subscription = query.subscribe();
        return subscription;
    }
}