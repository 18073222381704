import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Vehicle } from './vehicle';
import { Customer } from './customer';

export class Reservation {
    id: string = null;
    size: string = undefined;
    transmission: string = undefined;
    dateTime: NgbDate = null;
    docsRecieved: boolean = null;
    extraDrivers: number = null;
    vehicle: Vehicle = null;
    customer: Customer = null;
    youngDriver: boolean = null;
    complete: boolean = null;
    location: string = undefined;
    notes: string = null;
    depositReceived: boolean = null;
}