import { Injectable } from "@angular/core"; 
import { Part } from '../backoffice/models/part'; 
import { ParseService } from './parse.service'; 
import { Vehicle } from '../backoffice/models/vehicle';
import { VehicleService } from './vehicle.service';
 
@Injectable()
export class PartService { 
     
    constructor( 
        private parseService: ParseService,
        private vehicleService: VehicleService
    ) {}

    async fromParseObject(obj) {
        let part: Part = new Part();
        part.id = obj.id;
        part.description = obj.get("description");
        part.status = obj.get("status");

        if ( obj.get("vehicle") != null ) {
            part.vehicle = await this.vehicleService.fromParseObject(obj.get("vehicle"));
        } else {
            part.vehicle = null;
        }
        

        return part;
    }

    async fromParseObjectLoop(parts: any) {
        let results = [];
        for (var part of parts) {
            results.push(await this.fromParseObject(part))
        }
        return results;
    }
 
    save(part: Part): Promise<any> { 
        return new Promise( 
            async (resolve, reject) => { 
                await this.parseService.parseCloudRun("savePart", { part: {
                    id: part.id, 
                    vehicle: part.vehicle, 
                    description: part.description, 
                    status: part.status 
                }}).then( 
                    async (result) => { 
                        resolve(this.fromParseObject(result)); 
                    } 
                ), (error) => { 
                    reject(error); 
                } 
            } 
        ) 
    } 
 
    update(): Promise<any> { 
        return new Promise( 
            (resolve, reject) => { 
                reject("Error: Not Implemented."); 
            } 
        ) 
    } 
 
    delete(): Promise<any> { 
        return new Promise( 
            (resolve, reject) => { 
                reject("Error: Not Implemented."); 
            } 
        ) 
    } 
 
    getByID(objectID: String): Promise<any> { 
        return new Promise( 
            async (resolve, reject) => { 
                await this.parseService.parseCloudRun("getPartById", {id: objectID}).then(
                    async (result) => {
                        resolve(await this.fromParseObject(result));
                    }, (error) => {
                        reject(error);
                    }
                )
            } 
        ) 
    }

    getAll(): Promise<any> {
        return new Promise(
            async (resolve, reject) => {
                await this.parseService.parseCloudRun("getAllParts").then(
                    async (results) => {
                        resolve(await this.fromParseObjectLoop(results));
                    }, (error) => {
                        reject(error)
                    }
                )
            }
        )
    }

    getByStatus(status: String): Promise<any> {
        return new Promise(
            async (resolve, reject) => {
                await this.parseService.parseCloudRun("getPartsByStatus", {status: status}).then(
                    async (results) => {
                        console.log(results);
                        resolve(await this.fromParseObjectLoop(results));
                    }, (error) => {
                        reject(error)
                    }
                )
            }
        )
    }
 
    getSubscription() { 
        return false; 
    } 
} 