import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'list-contracts',
    template:
    `
    <table id="contract-list" class="table table-bordered dataTable no-footer dtr-inline" style="width:100%">
    <thead>
        <tr>
            <th>Contract ID</th>
            <th>Customer</th>
            <th>Vehicle</th>
            <th>Vehicle Status</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let contract of contracts" (click)="rowClicked(contract)" [ngClass]="{'alert-danger': contract.status == 'Ended', 'alert-info': contract.status == 'Active', 'alert-warning': contract.status == 'Draft'}">
            <td>{{ contract.id }}</td>
            <td>{{ contract.customer.firstName }} {{ contract.customer.lastName }}</td>
            <td>{{ contract.vehicle.registration }}</td>
            <td>{{ contract.vehicle.status }}</td>
            <td>{{ contract.status }}</td>
        </tr>
    </tbody>
    </table>
    `
})
export class ListContractsComponent implements OnInit {

    @Input() contracts = [];
    @Output() clicked = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }

}