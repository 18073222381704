import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalCustomerSelect } from '../../modals/customer-select/customer-select.component';

@Component({
    selector: 'form-prospect',
    templateUrl: './form-prospect.component.html'
})
export class FormProspectComponent implements OnInit {

    public companies: Array<string> = ["Purple Cars", "Rent to Buy", "Permits", "Holidays"];
    public statuses: Array<string>  = ["New Lead", "In Progress", "New Client", "Dead Lead", "Pending Vehicle"];
    public contactMethods: Array<string> = ["Call", "Email", "Facebook"];
    public leadSources: Array<string> = ["Client Referal", "Mailshot/Newsletter", "Websearch/Google", "Facebook Group/Post", "Printed Ad", "Referal by Staff", "Existing Client", "Miscellaneous"];
    public assignees: Array<string> = ["Greg", "Mandy", "Maria", "Sara", "Gemma", "Mike"];

    public customerName: string;

    @Input() public prospect: any;

    constructor(
        private modalService: NgbModal,
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if ( this.prospect.customer != null ) {
            this.customerName = this.prospect.customer.firstName + " " + this.prospect.customer.lastName;
        }
    }

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }
        
        return optionOne === optionTwo;
    }

    selectCustomerButton() {
        this.modalService.open(NgbdModalCustomerSelect, { size: 'lg' }).result.then((result) => {
            this.selectCustomer(result);
        }, (reason) => {
        });
    }

    selectCustomer(customer) {
        this.prospect.customer = customer;
        this.customerName = customer.firstName + " " + customer.lastName;
    }

}