import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ParseService } from '../../../services/parse.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBasic } from '../../modals/basic/basic.component';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    pushRightClass: string = 'push-right';
    currentUser: any;
    env: any  = environment;
    collapsed: boolean = false;

    @Output() collapsedEvent = new EventEmitter<boolean>();

    constructor(
        public router: Router,
        private parseService: ParseService,
        private modalService: NgbModal,
    ) {
        this.currentUser = this.parseService.getCurrentUser();
    }

    ngOnInit() { }

    toggleCollapsed() {
        console.log("Toggle Menu");
        this.collapsed = !this.collapsed;
        this.collapsedEvent.emit(this.collapsed);
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        this.parseService.logoutUser();
        this.router.navigate(['/']);
    }

    showVersion() {
        let modalRef = this.modalService.open(NgbdModalBasic);
        modalRef.componentInstance.modalTitle = 'Purple Cars Backoffice';
        modalRef.componentInstance.modalMessage = "Version: " + this.env.version;
    }

}
