import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalCustomerSelect } from '../../modals/customer-select/customer-select.component';
import { NgbdModalVehicleSelect } from '../../modals/vehicle-select/vehicle-select.component';
import { Customer } from '../../models/customer';
import { Vehicle } from '../../models/vehicle';

@Component({
    selector: 'form-contract',
    templateUrl: './form-contract.component.html'
})
export class FormContractComponent implements OnInit {

    public contractStatuses: Array<string> = ["Draft", "Pending", "Active", "Ended", "Cancelled"];
    public contractTypes: Array<string> = ["Short Term", "Long Term"];
    public paymentTypes: Array<string> = ["Stripe", "Transfer", "Cash", "Other"];

    public vehicleText: string;
    public customerText: string;

    @Input() public contract: any;

    constructor(
        private modalService: NgbModal,
    ) {}

    ngOnInit() { }

    ngOnChanges() {
        if ( this.contract.customer != null ) {
            this.customerText = this.contract.customer.firstName + " " + this.contract.customer.lastName;
        }

        if ( this.contract.vehicle != null ) {
            this.vehicleText = this.contract.vehicle.registration + " - " + this.contract.vehicle.make + " - " + this.contract.vehicle.model;
        }
    }

    selectCustomerButton() {
        this.modalService.open(NgbdModalCustomerSelect, { size: 'lg' }).result.then((result) => {
            this.selectCustomer(result);
        }, (reason) => {
        });
    }

    selectCustomer(customer: Customer) {
        this.contract.customer = customer;
        this.customerText = customer.firstName + " " + customer.lastName;
    }

    selectVehicleButton() {
        this.modalService.open(NgbdModalVehicleSelect, { size: 'lg' }).result.then((result) => {
            this.selectVehicle(result);
        }, (reason) => {
        });
    }

    selectVehicle(vehicle: Vehicle) {
        this.contract.vehicle = vehicle;
        this.vehicleText = vehicle.registration + " - " + vehicle.make + " - " + vehicle.model;
    }

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }
        return optionOne === optionTwo;
    }

}



