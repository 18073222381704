import { Component, OnInit, Input } from "@angular/core";
import { TaskService } from 'src/app/services/task.service';

@Component({
    selector: 'form-task',
    templateUrl: './form-task.component.html'
})
export class FromTaskComponent implements OnInit {
    public companies: Array<string> = ["Purple Cars", "Rent to Buy", "Permits", "Holidays"];
    public statuses: Array<string> = ["Open", "In Progress", "Complete"];
    public users: Array<any>;

    @Input() public task: any;

    constructor(
        private taskService: TaskService,
    ) { }

    async ngOnInit() {
        this.users = await this.taskService.getAllUsers();
    }

    compareFn(optionOne, optionTwo) {

        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }

        if ( optionOne.id == undefined || optionTwo.id == undefined ) {
            return optionOne === optionTwo;
        } else {
            return optionOne.id === optionTwo.id;
        }

        
    }
}