import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';

@Injectable()

export class ParseService {
    private parseAppId: string = environment.parseAppId;
    private parseMasterId: string = environment.parseMasterId;
    private parseServerUrl: string = environment.parseServerUrl;

    constructor() {
        this.parseInit();
    }

    parseInit() {
        Parse.initialize(this.parseAppId);
        (Parse as any).masterKey = this.parseMasterId;
        (Parse as any).serverURL = this.parseServerUrl;
        console.log("Parse Initialized");
    }

    public createParsePointer(className, objectID) {
        let pointer = {
            "__type": "Pointer",
            "className": className,
            "objectId": objectID,
          }
          return pointer;
    }

    public saveParseObject(obj) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                obj.save().then((result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                })
            }, 500);
        });
    }

    public findParseQuery(query: Parse.Query) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                query.limit(1000);
                query.find().then((results) => {
                    resolve(results);
                }, (error) => {
                    reject(error);
                });
            }, 500);
        });
    }

    public createParseFile(name, file) {
        return new Parse.File(name, file);
    }

    public createParseSubclass(name) {
        return Parse.Object.extend(name);
    }

    public createParseInstance(name) {
        var ParseObject = this.createParseSubclass(name);
        var parseObject = new ParseObject();
        return parseObject;
    }

    public createParseQuery(object) {
        var myQuery = new Parse.Query(object);
        return myQuery;
    }

    public getCurrentUser() {
        var currentUser = Parse.User.current();
        return currentUser;
    }

    public loginUser(username, password): Promise<any> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                Parse.User.logIn(username, password).then(function (user) {
                    resolve(user);
                }, (error) => {
                    reject(error);
                });
            }, 500);
        });
    }

    public logoutUser() {
        Parse.User.logOut();
    }

    toNgbDate(dateObj: Date) {
        let date: NgbDate = new NgbDate(
            dateObj.getUTCFullYear(),
            dateObj.getUTCMonth() + 1,
            dateObj.getUTCDate()
        );
        return date;
    }

    fromNgbDate(nbgDateObj) {
        if (typeof nbgDateObj === "string") {
            throw new Error("Failed to convert " + nbgDateObj + " to NgbDate");
        }

        if (nbgDateObj != null) {
            let date = new Date(Date.UTC(nbgDateObj.year, nbgDateObj.month - 1, nbgDateObj.day));
            return date;
        } else {
            return null;
        }

    }

    async parseCloudRun(job: string, params: any = undefined) {
        return await Parse.Cloud.run(job, params)
    }

    getStandardACL() {
        let acl = new Parse.ACL();
        acl.setRoleReadAccess("Administrators", true);
        acl.setRoleWriteAccess("Administrators", true);
        acl.setReadAccess(Parse.User.current(),true);
        acl.setWriteAccess(Parse.User.current(), true);
        return acl;
      }

}