import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleService } from 'src/app/services/vehicle.service';
import { NgbdModalBasic } from '../basic/basic.component';

@Component({
  selector: 'ngbd-modal-vehicle-select',
  templateUrl: './vehicle-select.component.html'
})
export class NgbdModalVehicleSelect {
  public vehicles = [];
  public filterString: any;

  @Input() modalTitle;
  @Input() modalMessage;

  constructor(
    public vehicleService: VehicleService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
    this.getAllVehicles();
  }

  getAllVehicles() {
    return this.vehicleService.getAllVehicles().then(
      (result: any) => {
        this.vehicles = result;
      }, (error) => {
        let modalRef = this.modalService.open(NgbdModalBasic);
        modalRef.componentInstance.modalTitle = 'Error loading vehicles!';
        modalRef.componentInstance.modalMessage = 'Error: ' + error;
      }
    )
  }

  filterVehicleByRegistration(registration: string): Promise<any> {
    return this.vehicleService.filterVehicleByRegistration(registration).then(
      (result: any) => {
        this.vehicles = result;
      }, (error) => {
        let modalRef = this.modalService.open(NgbdModalBasic);
        modalRef.componentInstance.modalTitle = 'Error loading vehicles!';
        modalRef.componentInstance.modalMessage = 'Error: ' + error;
      }
    )
  }

  rowClicked(event) {
    this.activeModal.close(event);
  }

}