<div class='card'>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="navbar-brand" href="#">Debts</div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto"></ul>
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <button style="margin-right: 5px;" type="button" class="btn btn-success"
                        routerLink="/backoffice/edit-debts">
                        <span _ngcontent-c3="" aria-hidden="true" class="fa fa-fw fa-plus"></span>
                    </button>
                </li>
                <li class="nav-item active">
                    <button style="margin-right: 5px;" type="button" class="btn btn-primary" (click)="findByStatus('open')">
                        <span _ngcontent-c3="" aria-hidden="true" class="fa fa-fw fa-refresh"></span>
                    </button>
                </li>
                <li>
                    <form class="form-inline my-2 my-lg-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fa fa-search"></i></div>
                            </div>
                            <input [(ngModel)]="filterString" type="search" class="form-control mr-sm-1"
                                placeholder="Search by Name" id="filterString" name="filterString">
                        </div>
                        <button class="btn btn-outline-success my-2 my-sm-0 mr-sm-1" type="submit"
                            (click)="findByCustomerName(filterString)">Search</button>
                    </form>
                </li>
                <li class="nav-item active">
                    <div ngbDropdown placement="bottom-right" class="d-inline-block">
                        <button class="btn btn-outline-primary mr-sm-1" id="dropdownBasic1" ngbDropdownToggle>Filter By Status</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="">
                            <button class="dropdown-item btn-sm" (click)="find()">All</button>
                            <button class="dropdown-item btn-sm" (click)="findByStatus('open')">Open</button>
                            <button class="dropdown-item btn-sm" (click)="findByStatus('closed')">Closed</button>
                       </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>

    <div class="card-body">
        <table id="notes-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
            <thead>
                <tr>
                    <th>Object ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Debt</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let debt of debts' [routerLink]="['/backoffice/edit-debts', debt.id]">
                    <td>{{debt.id}}</td>
                    <td>{{debt.customer.get('firstName')}}</td>
                    <td>{{debt.customer.get('lastName')}}</td>
                    <td>&euro;{{debt.debt}}</td>
                    <td>{{debt.status}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="card-footer text-muted">
        {{debts.length}} results shown.
    </div>

</div>