<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Object ID:</div>
            </div>
            <input [(ngModel)]="prospect.id" type="text" class="form-control" placeholder="New Prospect" id="id"
                name="id" disabled>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Company Required:</div>
            </div>
            <select [(ngModel)]="prospect.company" [compareWith]="compareFn" class="form-control" id="company"
                name="company">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let company of companies" [ngValue]="company">{{ company }}</option>
            </select>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Assigned To:</div>
            </div>
            <select [(ngModel)]="prospect.assignee" [compareWith]="compareFn" class="form-control" id="assignee"
                name="assignee">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let assignee of assignees" [ngValue]="assignee">{{ assignee }}</option>
            </select>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">First Name:</div>
            </div>
            <input [(ngModel)]="prospect.firstName" type="text" class="form-control" placeholder="First Name"
                id="firstName" name="firstName">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Last Name:</div>
            </div>
            <input [(ngModel)]="prospect.lastName" type="text" class="form-control" placeholder="Last Name"
                id="lastName" name="lastName">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Status:</div>
            </div>
            <select [(ngModel)]="prospect.status" [compareWith]="compareFn" class="form-control" id="status"
                name="status">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let status of statuses" [ngValue]="status">{{ status }}</option>
            </select>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Telephone:</div>
            </div>
            <input [(ngModel)]="prospect.telephone" type="text" class="form-control" placeholder="Telephone"
                id="telephone" name="telephone">
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Email:</div>
            </div>
            <input [(ngModel)]="prospect.email" type="text" class="form-control" placeholder="Email" id="email"
                name="email">
        </div>
    </div>
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">Contact Date</div>
            </div>
            <input [(ngModel)]="prospect.contactDate" class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker
                #contactDate="ngbDatepicker" id="contactDate" name="contactDate">
            <button class="input-group-addon" (click)="contactDate.toggle()" type="button">
                <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                <span class="fa fa-calendar"></span>
            </button>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Prefered Contact:</div>
            </div>
            <select [(ngModel)]="prospect.preferedContact" [compareWith]="compareFn" class="form-control"
                id="preferedContact" name="preferedContact">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let contactMethod of contactMethods" [ngValue]="contactMethod">{{ contactMethod }}
                </option>
            </select>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Lead Source:</div>
            </div>
            <select [(ngModel)]="prospect.leadSource" [compareWith]="compareFn" class="form-control" id="leadSource"
                name="leadSource">
                <option value="undefined" disabled selected>Please Select</option>
                <option *ngFor="let leadSource of leadSources" [ngValue]="leadSource">{{ leadSource }}
                </option>
            </select>
        </div>
    </div>
    <div class="col">
        <div class="input-group datepicker-input">
            <div class="input-group-prepend">
                <div class="input-group-text">Paid Customer Date</div>
            </div>
            <input [(ngModel)]="prospect.paidCustomerDate" class="form-control" placeholder="yyyy-mm-dd" ngbDatepicker
                #paidCustomerDate="ngbDatepicker" id="paidCustomerDate" name="paidCustomerDate">
            <button class="input-group-addon" (click)="paidCustomerDate.toggle()" type="button">
                <!-- <img src="img/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/> -->
                <span class="fa fa-calendar"></span>
            </button>
        </div>
    </div>
    <!--
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Customer Number:</div>
            </div>
            <input [(ngModel)]="prospect.customerNumber" type="text" class="form-control" placeholder="Customer Number"
                id="customerNumber" name="customerNumber">
        </div>
    </div>
    -->
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Customer:</div>
            </div>
            <input [(ngModel)]="customerName" disabled type="text" class="form-control" id="customer" name="customer">
            <div class="input-group-append">
                <button (click)="selectCustomerButton()" class="btn btn-primary" type="button">Select Customer</button>
            </div>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Follow up required:</div>
            </div>
            <input [(ngModel)]="prospect.followUp" type="text" class="form-control" placeholder="Follow up required"
                id="id" name="id">
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Notes:</div>
            </div>

            <textarea [(ngModel)]="prospect.serviceNotes" class="form-control" rows="8" id="serviceNotes"></textarea>

        </div>
    </div>
</div>