import { Injectable } from '@angular/core';
import { ParseService } from './parse.service';
import { Vehicle } from '../backoffice/models/vehicle';
import { Note } from '../backoffice/models/note';
import { NoteService } from './note.service';

@Injectable()
export class VehicleService {

    constructor(
        private parseService: ParseService,
        private noteService: NoteService,
    ) { }

    async fromParseObject(obj) {
        let vehicle: Vehicle = new Vehicle();
        vehicle.id = obj.id;
        vehicle.vehicleID = obj.get("vehicleID");
        vehicle.registration = obj.get("registration");
        vehicle.make = obj.get("make");
        vehicle.model = obj.get("model");
        vehicle.colour = obj.get("colour");
        vehicle.year = obj.get("year");
        vehicle.mileage = obj.get("mileage");
        vehicle.price = obj.get("price");
        vehicle.purchasePrice = obj.get("purchasePrice");
        vehicle.salePrice = obj.get("salePrice");
        vehicle.status = obj.get("status");
        vehicle.registraionStatus = obj.get("registraionStatus");
        vehicle.itvStatus = obj.get("itvStatus");
        vehicle.location = obj.get("location");
        vehicle.repairerName = obj.get("repairerName");
        vehicle.estimatedCompleteDate = obj.get("estimatedCompleteDate");
        vehicle.vehicleToRepairerDate = obj.get("vehicleToRepairerDate");
        vehicle.screenHide = obj.get("screenHide");
        //vehicle.image = obj.get("image");
        vehicle.permissoFile = obj.get("permissoFile");
        vehicle.insuranceFile = obj.get("insuranceFile");
        vehicle.fichaFrontFile = obj.get("fichaFrontFile");
        vehicle.fichaBackFile = obj.get("fichaBackFile");
        vehicle.greenCardFile = obj.get("greenCardFile");
        vehicle.brand = obj.get("brand");

        if (obj.get("itvDate") != null) {
            vehicle.itvDate = this.parseService.toNgbDate(obj.get("itvDate"));
        } else {
            vehicle.itvDate = obj.get("itvDate");
        }

        if (obj.get("itvBookingDate") != null) {
            vehicle.itvBookingDate = this.parseService.toNgbDate(obj.get("itvBookingDate"));
        } else {
            vehicle.itvBookingDate = obj.get("itvBookingDate");
        }

        if (obj.get("serviceDate") != null) {
            vehicle.serviceDate = this.parseService.toNgbDate(obj.get("serviceDate"));
        } else {
            vehicle.serviceDate = obj.get("serviceDate");
        }

        if (obj.get("insuranceExpieryDate") != null) {
            vehicle.insuranceExpieryDate = this.parseService.toNgbDate(obj.get("insuranceExpieryDate"));
        } else {
            vehicle.insuranceExpieryDate = obj.get("insuranceExpieryDate");
        }

        if (obj.get("purchaseDate") != null) {
            vehicle.purchaseDate = this.parseService.toNgbDate(obj.get("purchaseDate"));
        } else {
            vehicle.purchaseDate = obj.get("purchaseDate");
        }

        if (Array.isArray(obj.get("notes"))) {
            let notes = [];

            for (var note of obj.get("notes")) {
                let tmp = await note.fetch()
                notes.push(await this.noteService.fromParseObject(tmp));
            }
            vehicle.notes = notes;
        } else {
            vehicle.notes = [];
        }

        return vehicle;
    }

    async toParseObject(vehicle: Vehicle) {
        let obj = this.parseService.createParseInstance("Vehicle");
        obj.id = vehicle.id;
        obj.set("vehicleID", vehicle.vehicleID);
        obj.set("registration", vehicle.registration);
        obj.set("make", vehicle.make);
        obj.set("model", vehicle.model);
        obj.set("colour", vehicle.colour);
        obj.set("year", vehicle.year);
        obj.set("mileage", vehicle.mileage);
        obj.set("price", vehicle.price);
        obj.set("purchasePrice", vehicle.purchasePrice);
        obj.set("salePrice", vehicle.salePrice);
        obj.set("status", vehicle.status);
        obj.set("registraionStatus", vehicle.registraionStatus);
        obj.set("itvStatus", vehicle.itvStatus);
        obj.set("location", vehicle.location);
        obj.set("repairerName", vehicle.repairerName);
        obj.set("estimatedCompleteDate", vehicle.estimatedCompleteDate);
        obj.set("vehicleToRepairerDate", vehicle.vehicleToRepairerDate);
        obj.set("screenHide", vehicle.screenHide);
        //obj.set("image", vehicle.image);
        obj.set("permissoFile", vehicle.permissoFile);
        obj.set("insuranceFile", vehicle.insuranceFile);
        obj.set("fichaFrontFile", vehicle.fichaFrontFile);
        obj.set("fichaBackFile", vehicle.fichaBackFile);
        obj.set("greenCardFile", vehicle.greenCardFile);
        obj.set("brand", vehicle.brand);

        if (vehicle.itvDate != null) {
            obj.set("itvDate", this.parseService.fromNgbDate(vehicle.itvDate));
        } else {
            obj.set("itvDate", vehicle.itvDate);
        }

        if (vehicle.itvBookingDate != null) {
            obj.set("itvBookingDate", this.parseService.fromNgbDate(vehicle.itvBookingDate));
        } else {
            obj.set("itvBookingDate", vehicle.itvBookingDate);
        }

        if (vehicle.serviceDate != null) {
            obj.set("serviceDate", this.parseService.fromNgbDate(vehicle.serviceDate));
        } else {
            obj.set("serviceDate", vehicle.serviceDate);
        }

        if (vehicle.insuranceExpieryDate != null) {
            obj.set("insuranceExpieryDate", this.parseService.fromNgbDate(vehicle.insuranceExpieryDate));
        } else {
            obj.set("insuranceExpieryDate", vehicle.insuranceExpieryDate);
        }

        if (vehicle.purchaseDate != null) {
            obj.set("purchaseDate", this.parseService.fromNgbDate(vehicle.purchaseDate));
        } else {
            obj.set("purchaseDate", vehicle.purchaseDate);
        }

        if (Array.isArray(vehicle.notes)) {
            let notes = [];
            for (var note of vehicle.notes) {
                let obj = await this.noteService.toParseObject(note);
                notes.push(obj);
            }
            obj.set("notes", notes);
        } else {
            obj.set("notes", []);
        }

        return obj;
    }

    saveVehicle(obj: Vehicle): Promise<any> {
        return new Promise(async (resolve, reject) => {

            try {
                var vehicle = await this.toParseObject(obj);
            } catch(error) {
                reject(error);
                return;
            }

            vehicle.save().then(async (result) => {
                resolve(await this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getAllVehicles(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);
            let vehicles = [];

            query.limit(1000);
            query.find().then(async (results) => {
                for (let result of results) {
                    vehicles.push(await this.fromParseObject(result));
                }
                resolve(vehicles);
            }, (error) => {
                reject(error);
            })
        })
    }

    getCompanyVehicles(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);
            let vehicles = [];

            query.limit(1000);
            query.containedIn("status", ['Rented', 'Mechanical Repair', 'Available']);
            query.find().then(async (results) => {
                console.log(results.length);
                for ( let result of results ) {
                    vehicles.push(await this.fromParseObject(result));
                }
                resolve(vehicles);
            })
        })
    }

    getOffTheRoadVehicles(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);
            let vehicles = [];

            query.limit(1000);
            query.containedIn("status",
                ["Mechanical Repair", "ITV", "Paint Work"]);
            query.find().then(async (results) => {
                for ( let result of results ) {
                    vehicles.push(await this.fromParseObject(result));
                }
                resolve(vehicles);
            })
        })
    }

    getNotRentedVehicles(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);
            let vehicles = [];

            query.limit(1000);
            query.containedIn("status",
                ["Available"]);
            query.find().then(async (results) => {
                for ( let result of results ) {
                    vehicles.push(await this.fromParseObject(result));
                }
                resolve(vehicles);
            })
        })
    }

    getVehicleByStatus(status: string, screen: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);
            let vehicles = [];

            query.limit(1000);
            query.equalTo("status", status);
            if ( screen == true ) query.notEqualTo("screenHide", true);
            query.find().then(async (results) => {
                for (let result of results) {
                    vehicles.push(await this.fromParseObject(result));
                }
                resolve(vehicles);
            }, (error) => {
                reject(error);
            })
        })
    }

    filterVehicleByRegistration(registration: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);
            let vehicles = [];

            query.matches("registration", registration, 'i');
            query.find().then(async (results) => {
                for (let result of results) {
                    vehicles.push(await this.fromParseObject(result));
                }
                resolve(vehicles);
            }, (error) => {
                reject(error);
            })
        })
    }

    itvReport(): Promise<any> {
        return new Promise((resolve, reject) => {
            var date = new Date();
            date.setDate(date.getDate() + 21);

            var Vehicle = this.parseService.createParseSubclass("Vehicle");
            var query = this.parseService.createParseQuery(Vehicle);
            let vehicles = []

            query
                .limit(1000)
                .notEqualTo("screenHide", true)
                .notContainedIn('status', ['Sold', 'Archived', 'Write Off', 'Pending Delivery'])
                .lessThanOrEqualTo("itvDate", date)
                .ascending("itvDate")
                .find().then(async (results) => {
                    for (let result of results) {
                        vehicles.push(await this.fromParseObject(result));
                    }
                    resolve(vehicles);
                }, (error) => {
                    reject(error);
                });
        });
    }

    getVehicleById(objectID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);

            query.get(objectID).then(async (result) => {
                resolve(this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getSubscription() {
        var Vehicle = this.parseService.createParseSubclass("Vehicle");
        var query = this.parseService.createParseQuery(Vehicle);
        let subscription = query.subscribe();
        return subscription;
    }

    /* Reports */
    async getCountByStatus(status: string) {
        let Vehicle = this.parseService.createParseSubclass("Vehicle");
        let query = this.parseService.createParseQuery(Vehicle);
        query.equalTo("status", status);
        return await query.count();
    }

    async getActiveVehicleCount() {
        let Vehicle = this.parseService.createParseSubclass("Vehicle");
        let query = this.parseService.createParseQuery(Vehicle);
        query.notEqualTo("status", "Archived");
        return await query.count();
    }

    async getVehicleStatuses() {
        let Vehicle = this.parseService.createParseSubclass("Vehicle");
        let query = this.parseService.createParseQuery(Vehicle);
        return await query.distinct("status");
    }

    async countVehicleStatus(status: string) {
        return new Promise((resolve, reject) => {
            let Vehicle = this.parseService.createParseSubclass("Vehicle");
            let query = this.parseService.createParseQuery(Vehicle);
            query.equalTo("status", status);
            query.count().then((result) => {
                resolve(result);
            }, (error) => {
                reject(error);
            });
        });
    }

    async testCloudJob() {
        let tmp = await this.parseService.parseCloudRun("getAllUsers");
    }
}
