import { Injectable } from '@angular/core';
import { ParseService } from './parse.service';
import { Note } from '../backoffice/models/note';

@Injectable()
export class NoteService {

    constructor(
        private parseService: ParseService
    ) { }

    async fromParseObject(obj) {
        let note: Note = new Note();
        note.id = obj.id;
        note.title = obj.get("title");
        note.content = obj.get("content");
        note.status = obj.get("status");
        return note;
    }

    async toParseObject(note: Note) {
        let obj = this.parseService.createParseInstance("Note");
        obj.id = note.id;
        obj.set("title", note.title);
        obj.set("content", note.content);
        obj.set("status", note.status);
        return obj;
    }

    saveNote(note: Note): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let obj = await this.toParseObject(note);
            
            obj.save().then(async (result) => {
                resolve( await this.fromParseObject(result))
            }, (error) => {
                reject(error);
            })
        })
    }

}