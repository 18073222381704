import * as Parse from 'parse';
import { Customer } from './customer';
import { CustomerService } from 'src/app/services/customer.service';

export class Debt extends Parse.Object{

    constructor() {
        super("Debt");
    }

    get createdBy(): Parse.User {
        return this.get('createdBy');
    }

    set createdBy(value: Parse.User) {
        this.set('createdBy', value);
    }

    get customer(): any {
        return this.get('customer');
    }

    set customer(value: any) {
        this.set('customer', value)
    }

    get debt(): Number {
        return this.get('debt');
    }

    set debt(value: Number) {
        this.set('debt', value)
    }

    get status(): String {
        return this.get('status');
    }

    set status(value: String) {
        this.set('status', value)
    }

    get note(): String {
        return this.get('note');
    }

    set note(value: String) {
        this.set('note', value)
    }

}

Parse.Object.registerSubclass('Debt', Debt);