<form [formGroup]="pageForm" (ngSubmit)="submitButton(pageForm.value)">
    <mat-card class="page-card">
        <mat-card-title>Debt</mat-card-title>
        <mat-card-content>
            <div class="card-content">
                <mat-form-field>
                    <input matInput placeholder="ID" formControlName="idDisplay" id="idDisplay">
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Customer" formControlName="customerDisplay" id="customerDisplay" required>
                    <button mat-icon-button matSuffix color="primary" type="button" (click)="selectCustomerButton()">
                        <mat-icon>perm_identity</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field>
                    <span matPrefix>€&nbsp;</span>
                    <input matInput placeholder="Debt Amount" formControlName="debt" id="debt" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <select matNativeControl  formControlName="status" id="status" required>
                        <option value="open">Open</option>
                        <option value="closed">Closed</option>
                    </select>
                </mat-form-field>
                <mat-form-field>
                    <textarea matInput placeholder="Note" formControlName="note" id="note"></textarea>
                </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-actions align='end'>
            <button mat-raised-button color="primary">Save</button>
        </mat-card-actions>
    </mat-card>
</form>