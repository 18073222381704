import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'list-customers',
    template:
        `
        <table id="customer-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
        <thead>
            <tr>
                <th>Customer ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Telephone</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let customer of customers" (click)="rowClicked(customer)">
                <td>{{ customer.customerID }}</td>
                <td>{{ customer.firstName }}</td>
                <td>{{ customer.lastName }}</td>
                <td>{{ customer.contactNumber }}</td>
                <td>{{ customer.customerType }}</td>
            </tr>
        </tbody>
        </table>
        `
})
export class ListCustomersComponent implements OnInit {

    @Input() public customers = [];
    @Output() clicked = new EventEmitter<any>();

    ngOnInit() { }

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }
}