import { Injectable } from "@angular/core";
import { ParseService } from './parse.service';
import { Reservation } from '../backoffice/models/reservation';
import { Vehicle } from '../backoffice/models/vehicle';
import { Customer } from '../backoffice/models/customer';
import { VehicleService } from './vehicle.service';
import { CustomerService } from './customer.service';

@Injectable()
export class ReservationService {

    constructor(
        private parseService: ParseService,
        private vehicleService: VehicleService,
        private customerService: CustomerService,
    ) {}

        async fromParseObject(obj) {
            let reservation: Reservation = new Reservation();
            reservation.id = obj.id;
            reservation.size = obj.get("size");
            reservation.transmission = obj.get("transmission");
            reservation.docsRecieved = obj.get("docsRecieved");
            reservation.extraDrivers = obj.get("extraDrivers");
            reservation.youngDriver = obj.get("youngDriver");
            reservation.complete = obj.get("complete");
            reservation.location = obj.get("location");
            reservation.notes = obj.get("notes");
            reservation.depositReceived = obj.get("depositReceived");

            if (obj.get("dateTime") != null) {
                reservation.dateTime = this.parseService.toNgbDate(obj.get("dateTime"));
            }

            if (obj.get("vehicle") != null) {
                let tmp = await obj.get("vehicle").fetch();
                reservation.vehicle = await this.vehicleService.fromParseObject(tmp);
            }

            if (obj.get("customer") != null) {
                let customer = new Customer();
                let tmp = await obj.get("customer").fetch();
                reservation.customer = await this.customerService.fromParseObject(tmp);
            }

            return reservation;
        }

        toParseObject(reservation: Reservation) {
            let obj = this.parseService.createParseInstance("Reservation");
            obj.id = reservation.id;
            obj.set("size", reservation.size);
            obj.set("transmission", reservation.transmission);
            obj.set("docsRecieved", reservation.docsRecieved);
            obj.set("extraDrivers", reservation.extraDrivers);
            obj.set("youngDriver", reservation.youngDriver);
            obj.set("complete", reservation.complete);
            obj.set("location", reservation.location);
            obj.set("notes", reservation.notes);
            obj.set("depositReceived", reservation.depositReceived);

            if (reservation.dateTime != null) {
                obj.set("dateTime", this.parseService.fromNgbDate(reservation.dateTime));
            }

            if ( reservation.vehicle != null ) {
                let vehicle = this.parseService.createParseInstance("Vehicle");
                vehicle.id = reservation.vehicle.id
                obj.set("vehicle", vehicle);
            }

            if ( reservation.customer != null ) {
                let customer = this.parseService.createParseInstance("Customer");
                customer.id = reservation.customer.id
                obj.set("customer", customer);
            }

            return obj;
        }

        saveReservation(obj: Reservation): Promise<any> {
            return new Promise((resolve, reject) => {

                try {
                    var reservation = this.toParseObject(obj);
                } catch (error) {
                    reject(error);
                    return;
                }
                
                reservation.save().then((result) => {
                    resolve(this.fromParseObject(result));
                }, (error) => {
                    reject(error);
                })
            })
        }

        getAllReservations(): Promise<any> {
            return new Promise((resolve, reject) => {
                let Reservation = this.parseService.createParseInstance("Reservation");
                let query = this.parseService.createParseQuery(Reservation);
                let reservations = [];

                query.limit(1000);
                query.ascending("dateTime");
                query.find().then( async (results) => {
                    for ( var i in results ) {
                        reservations.push( await this.fromParseObject(results[i]));
                    }
                    resolve(reservations);
                }, (error) => {
                    reject(error)
                })
            })
        }

        getReservationById(objectID): Promise<any> {
            return new Promise((resolve, reject) => {
                let Reservation = this.parseService.createParseSubclass("Reservation");
                let query = this.parseService.createParseQuery(Reservation);
    
                query.get(objectID).then((result) => {
                    resolve(this.fromParseObject(result));
                }, (error) => {
                    reject(error);
                })
            })
        }

        getOpenReservations() {
            return new Promise((resolve, reject) => {
                let Reservation = this.parseService.createParseInstance("Reservation");
                let query = this.parseService.createParseQuery(Reservation);
                let reservations = [];

                query.limit(1000);
                query.notEqualTo("complete", true);
                query.ascending("dateTime");
                query.find().then( async (results) => {
                    for ( var i in results ) {
                        reservations.push( await this.fromParseObject(results[i]));
                    }
                    resolve(reservations);
                }, (error) => {
                    reject(error)
                })
            })
        }

        getClosedReservations() {
            return new Promise((resolve, reject) => {
                let Reservation = this.parseService.createParseInstance("Reservation");
                let query = this.parseService.createParseQuery(Reservation);
                let reservations = [];

                query.limit(1000);
                query.equalTo("complete", true);
                query.ascending("dateTime");
                query.find().then( async (results) => {
                    for ( var i in results ) {
                        reservations.push( await this.fromParseObject(results[i]));
                    }
                    resolve(reservations);
                }, (error) => {
                    reject(error)
                })
            })
        }

        filterByLastName(filter): Promise<any> {
            return new Promise((resolve, reject) => {
                let reservations = [];

                let Customer = this.parseService.createParseSubclass("Customer");
                let customerQuery = this.parseService.createParseQuery(Customer);
                customerQuery.matches("lastName", filter, 'i');


                let Reservation = this.parseService.createParseSubclass("Reservation");
                let reservationQuery = this.parseService.createParseQuery(Reservation);
                reservationQuery.matchesQuery("customer", customerQuery);
    
                reservationQuery.find().then( async (results) => {
                    for (var i in results) {
                        reservations.push( await this.fromParseObject(results[i]));
                    }
                    resolve(reservations)
                }, (error) => {
                    reject(error);
                })
            })
        }

        getSubscription() {
            var Reservation = this.parseService.createParseSubclass("Reservation");
            var query = this.parseService.createParseQuery(Reservation);
            let subscription = query.subscribe();
            return subscription;
        }
    
}