<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Contract ID:</div>
            </div>
            <input [(ngModel)]="contract.id" type="text" class="form-control" placeholder="Contract ID" id="id"
                name="id" disabled>
        </div>
    </div>
    <div class="col">
        <div class="form-group">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Status</div>
                </div>
                <select [(ngModel)]="contract.status" [compareWith]="compareFn" class="custom-select" id="status"
                    name="status">
                    <option value="undefined" disabled selected>Please Select</option>
                    <option *ngFor="let status of contractStatuses" [ngValue]="status">{{ status }}</option>
                </select>
            </div>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="form-group">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Contract Type</div>
                </div>
                <select [(ngModel)]="contract.contractType" [compareWith]="compareFn" class="custom-select" id="contractType"
                    name="contractType">
                    <option value="undefined" disabled selected>Please Select</option>
                    <option *ngFor="let contractType of contractTypes" [ngValue]="contractType">{{ contractType }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="form-group">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Payment Type</div>
                </div>
                <select [(ngModel)]="contract.paymentType" [compareWith]="compareFn" class="custom-select" id="paymentType"
                    name="paymentType">
                    <option value="undefined" disabled selected>Please Select</option>
                    <option *ngFor="let paymentType of paymentTypes" [ngValue]="paymentType">{{ paymentType }}</option>
                </select>
            </div>
        </div>
    </div>
</div>

<div class="form-row mb-3">
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Customer:</div>
            </div>
            <input [(ngModel)]="customerText" type="text" class="form-control" placeholder="Select Customer"
                id="customer" name="customer" disabled>
            <div class="input-group-append">
                <button (click)="selectCustomerButton()" class="btn btn-primary" type="button"><i
                        class="fa fa-user"></i></button>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">Vehicle Assigned:</div>
            </div>
            <input [(ngModel)]="vehicleText" type="text" class="form-control" placeholder="Select Vehicle" id="vehicle"
                name="vehicle" disabled>
            <div class="input-group-append">
                <button (click)="selectVehicleButton()" class="btn btn-primary" type="button"><i
                        class="fa fa-car"></i></button>
            </div>
        </div>
    </div>
</div>