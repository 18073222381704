import { Injectable, OnInit } from '@angular/core';
import { ParseService } from './parse.service';
import { Payment } from '../backoffice/models/payment';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { ContractService } from './contract.service';
import { CustomerService } from './customer.service';
import { VehicleService } from './vehicle.service';
import { Contract } from '../backoffice/models/contract';

@Injectable()
export class PaymentService implements OnInit {
    public payments = [];
    public filterString;

    constructor(
        private parseService: ParseService,
        private contractService: ContractService,
        private customerService: CustomerService,
        private vehicleService: VehicleService,
    ) { }

    ngOnInit() {}

    async fromParseObject(obj) {
        let payment: Payment = new Payment();
        payment.id = obj.id;
        payment.amount = obj.get("amount");
        payment.status = obj.get("status");

        if (obj.get("dueDate") != null) {
            payment.dueDate = this.parseService.toNgbDate(obj.get("dueDate"));
        } else {
            payment.dueDate = obj.get("dueDate");
        }

        if (obj.get("paidDate") != null) {
            payment.paidDate = this.parseService.toNgbDate(obj.get("paidDate"));
        } else {
            payment.paidDate = obj.get("paidDate");
        }

        if (obj.get("contract") != null) {
            let tmp = await obj.get("contract");
            payment.contract = await this.contractService.fromParseObject(tmp);
        }

        if (obj.get("customer") != null) {
            let tmp = await obj.get("customer");
            payment.customer = await this.customerService.fromParseObject(tmp);
        }

        if (obj.get("vehicle") != null) {
            let tmp = await obj.get("vehicle");
            payment.vehicle = await this.vehicleService.fromParseObject(tmp);
        }

        return payment;
    }

    async toParseObject(payment: Payment) {
        let obj = this.parseService.createParseInstance("Payment");
        obj.id = payment.id;
        obj.set("amount", Number(payment.amount));
        obj.set("status", payment.status);

        if (payment.dueDate != null) {
            obj.set("dueDate", this.parseService.fromNgbDate(payment.dueDate));
        }

        if (payment.paidDate != null) {
            obj.set("paidDate", this.parseService.fromNgbDate(payment.paidDate));
        }

        if ( payment.contract != null ) {
            let contract = this.parseService.createParseInstance("Contract");
            contract.id = payment.contract.id
            obj.set("contract", contract);
        }

        if ( payment.customer != null ) {
            let customer = this.parseService.createParseInstance("Customer");
            customer.id = payment.customer.id
            obj.set("customer", customer);
        }

        if ( payment.vehicle != null ) {
            let vehicle = this.parseService.createParseInstance("Vehicle");
            vehicle.id = payment.vehicle.id
            obj.set("vehicle", vehicle);
        }

        return obj;
    }

    savePayment(obj: Payment): Promise<any> {
        return new Promise(async (resolve, reject) => {

            try {
                var payment = await this.toParseObject(obj);
            } catch (error) {
                reject(error);
                return;
            }
            
            payment.save().then((result) => {
                resolve(this.fromParseObject(result));
            }, (error) => {
                reject(error);
            })
        })
    }

    getPaymentByID(objectID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let Payment = this.parseService.createParseSubclass("Payment");
            let query = this.parseService.createParseQuery(Payment);
            query.get(objectID).then( async (result) => {
                resolve(this.fromParseObject(result))
            }, (error) => {
                reject(error);
            })
        })
    }

    getAllPayments(): Promise<any> {
        return new Promise((resolve, reject) => {
            let Payment = this.parseService.createParseSubclass("Payment");
            let query = this.parseService.createParseQuery(Payment);
            let payments = [];

            query.limit(1000);
            query.ascending("dueDate");
            query.find().then( async (results) => {
                for ( var result of results ) {
                    payments.push( await this.fromParseObject(result) );
                }
                resolve(payments);
            }, (error) => {
                reject(error);
            })
        })
    }

    getPaymentsByStatus(status: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let Payment = this.parseService.createParseSubclass("Payment");
            let query = this.parseService.createParseQuery(Payment);
            let payments = [];

            query.include("contract");
            query.include("customer");
            query.include("vehicle");
            query.limit(1000);
            query.equalTo("status", status);
            query.ascending("dueDate");
            query.find().then( async (results) => {
                for ( var result of results ) {
                    payments.push( await this.fromParseObject(result) );
                }
                resolve(payments);
            }, (error) => {
                reject(error);
            })
        })
    }

    getPaymentByContract(contract: Contract): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let Payment = this.parseService.createParseSubclass("Payment");
            let query = this.parseService.createParseQuery(Payment);
            let payments = [];

            query.include("contract");
            query.include("customer");
            query.include("vehicle");
            query.limit(1000);
            query.equalTo("contract", await this.contractService.toParseObject(contract));
            query.ascending("dueDate");
            query.find().then( async (results) => {
                for ( var result of results ) {
                    payments.push( await this.fromParseObject(result) );
                }
                resolve(payments);
            }, (error) => {
                reject(error);
            })
        })
    }

    searchPaymentsByCustomerLastName(lastName): Promise<any> {
        return new Promise((resolve, reject) => {
            let Customer = this.parseService.createParseSubclass("Customer");
            let customerQuery = this.parseService.createParseQuery(Customer);
            customerQuery.matches("lastName", lastName, 'i');

            let Payment = this.parseService.createParseSubclass("Payment");
            let paymentQuery = this.parseService.createParseQuery(Payment);
            let payments = [];

            paymentQuery.include("contract");
            paymentQuery.include("customer");
            paymentQuery.include("vehicle");
            paymentQuery.matchesQuery("customer", customerQuery);
            paymentQuery.find().then( async (results) => {
                for ( var result of results ) {
                    payments.push( await this.fromParseObject(result) );
                }
                resolve(payments);
            }, (error) => {
                reject(error);
            })
        })
    }

    getSubscription() {
        var Payment = this.parseService.createParseSubclass("Payment");
        var query = this.parseService.createParseQuery(Payment);
        let subscription = query.subscribe();
        return subscription;
    }

}