import { Component, OnInit, Input } from "@angular/core";
import { Reservation } from '../../models/reservation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalCustomerSelect } from '../../modals/customer-select/customer-select.component';
import { NgbdModalVehicleSelect } from '../../modals/vehicle-select/vehicle-select.component';
import { Vehicle } from '../../models/vehicle';
import { Customer } from '../../models/customer';

@Component({
    selector: 'form-reservation',
    templateUrl: './form-reservation.component.html'
})
export class FormReservationComponent implements OnInit {

    public vehicleSizes: Array<string> = ["Small", "Medium", "Large", "Runabout"];
    public vehicleTransmissions: Array<string> = ["Automatic", "Manual"];
    public trasferLocations: Array<string> = ["No", "Gibraltar", "Malaga"];

    public vehicleText: string = "TBA";
    public customerText: string;

    @Input() public reservation: Reservation;

    constructor(
        private modalService: NgbModal,
    ) {}

    ngOnInit() {}

    ngOnChanges() {
        if ( this.reservation.customer != null ) {
            this.customerText = this.reservation.customer.firstName + " " + this.reservation.customer.lastName;
        }

        if ( this.reservation.vehicle != null ) {
            this.vehicleText = this.reservation.vehicle.registration + " - " + this.reservation.vehicle.make + " - " + this.reservation.vehicle.model;
        }
    }

    selectCustomerButton() {
        this.modalService.open(NgbdModalCustomerSelect, { size: 'lg' }).result.then((result) => {
            this.selectCustomer(result);
        }, (reason) => {
        });
    }

    selectVehicleButton() {
        this.modalService.open(NgbdModalVehicleSelect, { size: 'lg' }).result.then((result) => {
            this.selectVehicle(result);
        }, (reason) => {
        });
    }

    removeVehicleButton() {
        this.removeVehicle();
    }

    selectCustomer(customer: Customer) {
        this.reservation.customer = customer;
        this.customerText = customer.firstName + " " + customer.lastName;
    }

    selectVehicle(vehicle: Vehicle) {
        this.reservation.vehicle = vehicle;
        this.vehicleText = vehicle.registration + " - " + vehicle.make + " - " + vehicle.model;
    }

    removeVehicle() {
        this.reservation.vehicle = null;
        this.vehicleText = "TBA";
    }

    compareFn(optionOne, optionTwo) {
        if (typeof optionOne === "undefined") {
            return false;
        }

        if (optionOne === null) {
            return false;
        }

        if (typeof optionTwo === "undefined") {
            return false;
        }

        if (optionTwo === null) {
            return false;
        }
        
        return optionOne === optionTwo;
    }
}