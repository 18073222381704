import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Payment } from '../../models/payment';

@Component({
  selector: 'ngbd-modal-payment-select',
  template:
  `
  <div class="modal-header">
  <h4 class="modal-title">{{ modalTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="modal-body">
      <list-payments [payments]="payments" (clicked)="rowClicked($event)"></list-payments>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Close</button>
  </div>
  `
})
export class NgbdModalPaymentSelect {
  @Input() modalTitle;
  @Input() modalMessage;
  @Input() payments: Array<Payment>;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  rowClicked(event) {
    this.activeModal.close(event);
  }

}