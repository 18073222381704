import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: 'list-reservations',
    template:
    `
    <table id="notes-list" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" style="width:100%">
    <thead>
        <tr>
            <th>Object ID</th>
            <th>Customer</th>
            <th>Vehicle</th>
            <th>Transmission</th>
            <th>Date</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let reservation of reservations" (click)="rowClicked(reservation)">
            <td>{{ reservation.id }}</td>
            <td *ngIf="reservation.customer != null">{{ reservation.customer.firstName }} {{ reservation.customer.lastName }}</td>
            <td *ngIf="reservation.customer == null"></td>
            <td *ngIf="reservation.vehicle != null">{{ reservation.vehicle.registration }}</td>
            <td *ngIf="reservation.vehicle == null">{{ reservation.size }}</td>
            <td>{{ reservation.transmission }}</td>
            <td *ngIf="reservation.dateTime != null">{{ reservation.dateTime.day }}-{{ reservation.dateTime.month }}-{{ reservation.dateTime.year }}</td>
            <td *ngIf="reservation.dateTime == null"></td>
            <td *ngIf="reservation.complete != true">Open</td>
            <td *ngIf="reservation.complete == true">Complete</td>
        </tr>
    </tbody>
</table>
    `
})
export class ListReservations implements OnInit {
    @Input() public reservations;
    @Output() clicked = new EventEmitter<any>();

    ngOnInit() {}

    // Emmit an event when a row is clicked.
    private rowClicked(obj) {
        this.clicked.emit(obj);
    }
}